var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M19.7 6.02a.38.38 0 0 0-.25 0l-8.1 2.78a.37.37 0 0 0-.25.36.37.37 0 0 0 .27.35l1.92.55-.01 1.88-.01.07V15c0 1.2.86 1.98 1.69 2.75l.38.35c.76.72 1.44 1.4 1.82 1.77l.18.19.04.04.06.05v.01a.12.12 0 0 1 .06.1c0 .02-.01.06-.1.14 0 0-.93 1-1.7 1.79l-.52.5c-1.06 1.07-1.91 2.02-1.91 2.83v4.56h-1.9a.38.38 0 0 0 0 .75h16.06a.38.38 0 0 0 0-.75h-1.86v-4.56c0-.85-1.1-1.97-2.06-2.97l-.35-.36a155.55 155.55 0 0 0-1.77-1.83.12.12 0 0 1-.05-.1c0-.02 0-.07.1-.14l.9-.93.37-.36c.28-.29.56-.55.75-.73l.34-.3c.99-.9 1.77-1.6 1.77-2.8v-2.72l-.05-2.42a.38.38 0 0 0-.48-.35l-5.38 1.56-6.93-1.95 6.85-2.35 7.18 2.31v6.11a.38.38 0 0 0 .75 0V8.81a.38.38 0 0 0-.26-.36zm-5.66 4.24l5.52 1.56c.07.02.14.02.21 0l5.01-1.46.04 1.64v.01l-5.42 1.75-5.37-1.75zm-.02 2.54l5.26 1.71a.37.37 0 0 0 .23 0l5.3-1.71V15c0 .86-.6 1.4-1.51 2.24l-.35.31-.63.6-.24.25a82.23 82.23 0 0 0-1.15 1.16.86.86 0 0 0-.34.7c0 .27.13.53.3.66l1.73 1.79.36.37c.73.75 1.84 1.9 1.84 2.44v2.13c-1.4-1.35-4.42-2.82-5.39-2.82-.94 0-3.99 1.53-5.41 2.9v-2.2c0-.55.91-1.52 1.61-2.22l.27-.27.33-.33.12-.12.25-.26.24-.25a54.7 54.7 0 0 0 1.06-1.13.86.86 0 0 0 .35-.7.83.83 0 0 0-.3-.65l-.19-.18-.21-.21-.18-.18c-.39-.38-.93-.92-1.51-1.47l-.4-.36c-.77-.72-1.44-1.34-1.44-2.2zm5.48 2.35c-.37 0-.62.2-.62.51 0 .45.24 1.59.61 1.59.38 0 .63-1.19.63-1.59 0-.3-.25-.5-.62-.5zm.02 2.93c-.2 0-.37.22-.37.49s.16.49.37.49c.2 0 .36-.22.36-.5s-.16-.48-.36-.48zm-.09 7.5c1.2 0 5.38 2.54 5.38 3.27v1.23H14.03V28.93c.01-.72 4.26-3.35 5.41-3.35z\"/></svg>";
},"useData":true});