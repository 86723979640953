var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.57 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M6.29 12.24h4.95c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H6.29a.5.5 0 0 0-.5.5c0 .27.22.5.5.5zm0 1.91h4.95c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H6.29c-.28 0-.5.22-.5.5s.22.5.5.5z\"/><path fill=\"currentColor\" d=\"M15 5.26h-1.31V2.65a.5.5 0 0 0-.5-.5H4.35a.5.5 0 0 0-.5.5v2.61H2.54c-.87 0-1.57.71-1.57 1.57v4.79c0 .87.71 1.57 1.57 1.57h1.31v2.16c0 .28.22.5.5.5h8.84a.5.5 0 0 0 .5-.5v-2.16H15c.87 0 1.57-.71 1.57-1.57V6.83c0-.87-.71-1.57-1.57-1.57zM4.85 3.15h7.84v2.11H4.85V3.15zm7.84 11.7H4.85v-4.37h7.84v4.37zm2.88-3.23c0 .32-.26.57-.57.57h-1.31V9.98a.5.5 0 0 0-.5-.5H4.35a.5.5 0 0 0-.5.5v2.21H2.54a.57.57 0 0 1-.57-.57V6.83c0-.32.26-.57.57-.57H15c.32 0 .57.26.57.57v4.79z\"/></svg>";
},"useData":true});