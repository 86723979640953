var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M25.07 7a.37.37 0 0 0-.41.27c-.02.05-.36 1.27.4 2.54.6 1 1.7 1.73 3.27 2.17a7.08 7.08 0 0 0-.48.81l-.01.02h-.02c-3.55-1.2-5.3 2.15-5.63 2.9l-1.81.88a.38.38 0 0 0-.17.5.37.37 0 0 0 .5.18l1.93-.96a.37.37 0 0 0 .19-.2c.06-.15 1.47-3.7 4.75-2.6 2.02.69 2.6 2.37 1.8 5.14l-.07.23c-.14.6-.15 1.53.66 4.08.44 1.36-.1 3.51-1.23 5-.88 1.13-1.98 1.74-3.1 1.67-.25 0-.39.15-.4.36a.37.37 0 0 0 .36.4h.18c1.31 0 2.57-.7 3.55-1.98 1.3-1.69 1.87-4.08 1.36-5.68-.77-2.39-.76-3.2-.65-3.69l.05-.18c1-3.41-.15-5-1.56-5.75.55-1.03.86-1.55 2.7-1.38.22.03.39-.13.4-.33a.38.38 0 0 0-.33-.41c-.86-.08-1.46-.01-1.92.17-.14-1.19-.85-3.65-4.31-4.16zm.25.8c2.73.57 3.24 2.58 3.33 3.5-1.44-.39-2.43-1.01-2.95-1.86a2.71 2.71 0 0 1-.38-1.63zm-6.54 2.89a5.04 5.04 0 0 0-4.56 3.69 3.44 3.44 0 0 0-.42-.67.37.37 0 0 0-.53 0 .38.38 0 0 0 0 .53c.22.24 1.19 2.23 1.49 3.51-.91-.35-2.67-.92-4.21-.3-2.06.81-3.62 1.8-3.55 6.27.04 2.55 1.11 4.83 2.79 5.96a4.23 4.23 0 0 0 4.03.4.37.37 0 0 0 .21-.48.37.37 0 0 0-.48-.21 3.5 3.5 0 0 1-3.35-.34c-1.47-.98-2.41-3.03-2.45-5.34-.06-4.02 1.18-4.81 3.07-5.57 1.51-.6 3.4.2 4.03.46.43.18.37.17.75 0 .57-.28 2.31-1.09 3.94-.44 1.92.77 2.74 1.93 3.16 4.42a.38.38 0 0 0 .44.31.37.37 0 0 0 .3-.43c-.46-2.77-1.44-4.12-3.62-5-1.72-.68-3.47-.02-4.29.34-.11-.6-.37-1.33-.65-2.02 4.83-1.1 4.32-4.74 4.3-4.78a.38.38 0 0 0-.4-.31zm-.31.8c-.02.82-.41 2.78-3.66 3.55.19-.97.9-3.06 3.66-3.55zM12.55 23.2a.38.38 0 0 0-.38.37c0 4.32 3.38 7.58 7.85 7.58 4.48 0 7.85-3.26 7.85-7.58a.38.38 0 0 0-.38-.37zm.39.75h1.11c.1 1.4.65 2.7 1.65 3.7a5.97 5.97 0 0 0 4.25 1.68h.08c3.26-.03 5.75-2.32 5.94-5.38h1.14c-.19 3.7-3.17 6.45-7.09 6.45s-6.9-2.75-7.09-6.45zm1.86 0h10.4c-.18 2.67-2.3 4.6-5.18 4.63-1.46.02-2.8-.5-3.79-1.47a4.91 4.91 0 0 1-1.43-3.16zm4.25.64a.38.38 0 0 0 0 .75H21a.38.38 0 0 0 0-.75z\"/></svg>";
},"useData":true});