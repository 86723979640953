var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M23.18 7c-.49 0-.98.18-1.33.53l-.15.15a1.88 1.88 0 0 0-.53 1.08l-.72-.73a1.87 1.87 0 0 0-1.33-.55c-.5 0-.98.2-1.33.55l-.15.15a1.88 1.88 0 0 0 0 2.66l3.18 3.18-5.78 5.78-3.18-3.18a1.86 1.86 0 0 0-1.33-.55c-.5 0-.98.2-1.33.55l-.15.15a1.87 1.87 0 0 0-.55 1.33c0 .5.2.97.55 1.33l.72.72a1.88 1.88 0 0 0-1.07.53l-.15.15A1.87 1.87 0 0 0 8 22.16c0 .5.2.97.55 1.33l1.4 1.4-1.4 1.4a.38.38 0 0 0 0 .53l1.78 1.78a.37.37 0 0 0 .53 0l1.4-1.4 1.4 1.4a1.86 1.86 0 0 0 1.33.55c.5 0 .98-.2 1.33-.55l.15-.15c.3-.29.48-.67.53-1.07l.72.72a1.89 1.89 0 0 0 2.67 0l.14-.15a1.89 1.89 0 0 0 0-2.66l-3.18-3.18 5.78-5.78 3.18 3.18a1.89 1.89 0 0 0 2.66 0l.15-.14a1.88 1.88 0 0 0 0-2.66l-.72-.73a1.87 1.87 0 0 0 1.07-.53l.15-.15a1.88 1.88 0 0 0 0-2.66l-1.4-1.4 1.4-1.4a.37.37 0 0 0 0-.53l-1.78-1.78a.37.37 0 0 0-.53 0l-1.4 1.4-1.4-1.4A1.88 1.88 0 0 0 23.18 7zm0 .74c.3 0 .59.1.8.32l5.1 5.11c.45.44.45 1.16 0 1.6l-.14.15a1.13 1.13 0 0 1-1.6 0l-5.1-5.11a1.13 1.13 0 0 1 0-1.6l.14-.15c.21-.21.5-.32.8-.32zm-4.07.5c.3 0 .6.11.8.32l8.68 8.68c.44.44.44 1.16 0 1.6l-.15.14c-.44.45-1.16.44-1.6 0l-3.44-3.44-1.78-1.78-3.45-3.45a1.13 1.13 0 0 1 0-1.6l.14-.15c.22-.2.5-.33.8-.33zm8.47.09l1.24 1.25-1.13 1.13-1.25-1.25zm-6.23 6.22l1.25 1.25-5.78 5.78-1.25-1.25zm-10.82 2.27c.3 0 .59.11.8.33l3.38 3.37c.01.04 0 .08.04.1.02.03.07.03.1.05l1.67 1.67.02.05c.02.01.03 0 .05.02L20 25.82c.44.44.44 1.16 0 1.6l-.14.15a1.13 1.13 0 0 1-1.6 0l-8.68-8.68a1.12 1.12 0 0 1-.33-.8c0-.3.12-.58.33-.8l.15-.14c.21-.22.5-.33.8-.33zm-.5 4.06c.29 0 .58.11.8.33l5.1 5.11a1.12 1.12 0 0 1 0 1.6l-.14.15c-.42.43-1.17.43-1.6 0l-3.4-3.4c0-.02 0-.04-.02-.06l-.07-.04-1.62-1.61a1.13 1.13 0 0 1 0-1.6l.15-.15c.22-.22.5-.33.8-.33zm.45 4.54l1.25 1.25-1.14 1.13-1.24-1.24z\"/></svg>";
},"useData":true});