var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":101},"end":{"line":1,"column":109}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 10.5 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":116}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":117},"end":{"line":1,"column":126}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M8.3 1.78H2.2c-.66 0-1.2.53-1.2 1.2v11.7c0 .66.54 1.2 1.2 1.2h6.1c.66 0 1.2-.54 1.2-1.2V2.98c0-.67-.54-1.2-1.2-1.2zm-6.1 1h6.1c.11 0 .2.09.2.2v.66H2v-.66c0-.11.09-.2.2-.2zm6.3 9.75H2v-7.9h6.5v7.9zm-.2 2.35H2.2a.2.2 0 0 1-.2-.2v-1.14h6.5v1.14a.2.2 0 0 1-.2.2z\"/><path fill=\"currentColor\" d=\"M5.25 13.74c-.25 0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.19-.44-.44-.44zm-2.61-7c.13 0 .26-.05.35-.15l.91-.91c.2-.2.2-.51 0-.71s-.51-.2-.71 0l-.91.92a.5.5 0 0 0 0 .71c.1.09.23.14.36.14zm.96.21a.48.48 0 0 0 .7 0l1.19-1.18c.2-.2.2-.51 0-.71s-.51-.2-.71 0L3.6 6.24a.5.5 0 0 0 0 .71zm-.85.21l-.32.32a.5.5 0 0 0 0 .71.48.48 0 0 0 .7 0l.33-.33c.2-.2.2-.51 0-.71s-.51-.19-.71.01z\"/></svg>";
},"useData":true});