var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":142},"end":{"line":1,"column":150}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 24 24\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":122},"end":{"line":1,"column":157}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":158},"end":{"line":1,"column":167}}}) : helper)))
    + "\"><defs><path d=\"M0 0h20v20H0z\" fill=\"currentColor\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"currentColor\" d=\"M12.1 11.15L9.44 7.34H8.02l3.3 4.72.41.6 2.82 4.03h1.43l-3.47-4.95z\"/><g transform=\"translate(2 2)\"><mask fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M12.12 15.36L9.26 11.2l-3.58 4.16h-.92l4.1-4.76-4.1-5.96h3.12l2.7 3.94 3.4-3.94h.92L11 9.18l4.24 6.18h-3.12zM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20z\" fill=\"currentColor\" mask=\"url(#b)\"/></g></g></svg>";
},"useData":true});