var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":142},"end":{"line":1,"column":150}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 24 24\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":122},"end":{"line":1,"column":157}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":158},"end":{"line":1,"column":167}}}) : helper)))
    + "\"><defs><path d=\"M0 0h20v20H0z\" fill=\"currentColor\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M15.14 9.56a.7.7 0 1 1 0-1.42.7.7 0 0 1 0 1.42m-3.13 5.47a3.03 3.03 0 1 1-.02-6.06 3.03 3.03 0 0 1 .02 6.06m4.78-5.42a3.25 3.25 0 0 0-.2-1.1c-.11-.27-.24-.46-.45-.67-.2-.2-.4-.33-.67-.44-.21-.08-.52-.18-1.1-.2-.62-.03-.8-.03-2.38-.03-1.57 0-1.76 0-2.38.04-.57.03-.89.12-1.1.2-.27.11-.46.24-.67.45-.2.2-.33.4-.44.67-.08.21-.18.52-.2 1.1-.03.62-.03.8-.03 2.38 0 1.57 0 1.76.04 2.38.03.57.12.89.2 1.1.11.27.24.46.45.67.2.2.4.33.67.44.21.08.52.18 1.1.2.62.03.8.03 2.38.03 1.57 0 1.76 0 2.38-.04.57-.03.89-.12 1.1-.2.27-.11.46-.24.67-.45.2-.2.33-.4.44-.67.08-.21.18-.52.2-1.1.03-.62.03-.8.03-2.38 0-1.57 0-1.76-.04-2.38\" fill=\"currentColor\"/><path d=\"M12 10.04a1.96 1.96 0 1 0 0 3.92 1.96 1.96 0 0 0 0-3.92\" fill=\"currentColor\"/><g transform=\"translate(2 2)\"><mask fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M15.86 12.42a4.32 4.32 0 0 1-.27 1.43c-.15.39-.35.72-.68 1.04a2.9 2.9 0 0 1-1.04.69c-.37.14-.8.24-1.43.27-.63.03-.83.04-2.43.04s-1.8 0-2.43-.03a4.33 4.33 0 0 1-1.43-.27 2.89 2.89 0 0 1-1.04-.68 2.9 2.9 0 0 1-.69-1.04c-.14-.37-.24-.8-.27-1.43-.03-.63-.04-.83-.04-2.43s0-1.8.03-2.43c.03-.63.12-1.05.27-1.43.15-.39.35-.72.68-1.04.32-.33.65-.53 1.04-.69.37-.14.8-.24 1.43-.27.63-.03.83-.04 2.43-.04s1.8 0 2.43.03a4.3 4.3 0 0 1 1.43.27c.39.15.72.35 1.04.68.33.32.53.65.69 1.04.14.37.24.8.27 1.43.03.63.04.83.04 2.43s0 1.8-.03 2.43M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20\" fill=\"currentColor\" mask=\"url(#b)\"/></g></g></svg>";
},"useData":true});