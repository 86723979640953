var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M13.88 11c-.75 0-1.47.47-1.84 1.2a79.3 79.3 0 0 0-.22.45l-.14.28-.25.49-.16.33-.09.17-.16.32-.12.25-.21-.32a.38.38 0 0 0-.31-.17h-2.5a.88.88 0 0 0-.88.87v1c0 .49.4.87.78.87l1.49.37-.2.3a3.36 3.36 0 0 0-.57 1.87v3.6c0 .49.19.93.5 1.27v1.84c0 .48.4.88.88.88h3.5a.88.88 0 0 0 .87-.88v-.5a.38.38 0 0 0-.75 0v.5a.13.13 0 0 1-.12.13h-3.5a.13.13 0 0 1-.13-.13v-1.35c.2.07.4.11.63.11h17c.22 0 .43-.04.62-.1v1.34a.13.13 0 0 1-.13.13h-3.5a.13.13 0 0 1-.12-.13v-.5a.38.38 0 0 0-.75 0v.5c0 .48.4.88.88.88h3.5a.88.88 0 0 0 .87-.88v-1.84c.31-.34.5-.78.5-1.28v-3.59a3.37 3.37 0 0 0-.57-1.87l-.2-.3 1.4-.36a.88.88 0 0 0 .87-.88v-1a.88.88 0 0 0-.88-.87h-2.5a.38.38 0 0 0-.3.17l-.22.32-.12-.25-.16-.32-.09-.17-.16-.33-.61-1.21A2.12 2.12 0 0 0 23.88 11zm0 .75h10c.52 0 .96.4 1.16.8l.44.87.25.5.4.81.26.5-.25.33-1.32.44H12.94l-1.32-.44-.25-.34.11-.22.07-.13.22-.45.25-.5.25-.5.44-.87c.2-.4.64-.8 1.17-.8zm-6 3h2.3l.88 1.33a.37.37 0 0 0 .2.15l1.5.5.11.02h12a.36.36 0 0 0 .12-.02l1.5-.5a.38.38 0 0 0 .2-.15l.89-1.33h2.3c.06 0 .12.06.12.13v1c0 .06-.06.12-.22.13l-2 .5a.37.37 0 0 0-.22.57l.5.75c.29.43.44.93.44 1.45v3.6c0 .62-.5 1.12-1.13 1.12h-17c-.61 0-1.12-.5-1.12-1.13v-3.59c0-.52.15-1.02.44-1.45l.5-.75a.37.37 0 0 0-.22-.57L7.87 16a.13.13 0 0 1-.12-.13v-1c0-.06.06-.12.13-.12zm2.5 3.75a.38.38 0 0 0-.27.64l1 1c.07.07.17.11.27.11h2.5a.37.37 0 1 0 0-.75h-2.35l-.89-.89a.38.38 0 0 0-.27-.11zm17 0a.38.38 0 0 0-.27.11l-.89.89h-2.34a.38.38 0 0 0 0 .75h2.5c.1 0 .19-.04.26-.11l1-1a.38.38 0 1 0-.27-.64zM13.88 22a.38.38 0 0 0 0 .75h10a.38.38 0 0 0 0-.75z\"/></svg>";
},"useData":true});