var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-spot__svg--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":114},"end":{"line":1,"column":122}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewbox=\"0 0 150 100\" class=\"m-spot__svg "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":89},"end":{"line":1,"column":129}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"svgClass") || (depth0 != null ? lookupProperty(depth0,"svgClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"svgClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":130},"end":{"line":1,"column":142}}}) : helper)))
    + "\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#F5F7F7\" d=\"M4 53.6C9 41.5 52.4 18 91 24c15.9 2.5 51.5 12.5 56.4 25.4 11.7 30.4-25.3 47.3-51.5 49-34.9 2.4-79.1-6.2-84.3-13.6-4.6-6.5-13-19.1-7.8-31.2z\"/><path fill=\"#FFF\" d=\"M99.5 68.2h-47a1 1 0 0 1-1-1V4.5c0-.6.5-1 1-1h47c.5 0 1 .5 1 1v62.7c0 .5-.5 1-1 1z\"/><path fill=\"#E3E6E9\" d=\"M100.5 18.3h-49V4.6c0-.6.5-1.1 1-1.1h47c.5 0 1 .5 1 1.1v13.7z\"/><path fill=\"#7C8797\" d=\"M100.5 60a.7.7 0 0 1-.7-.7v-1.5c0-.4.3-.7.7-.7s.7.3.7.7v1.5c0 .4-.4.7-.7.7z\"/><path fill=\"#7C8797\" d=\"M100.5 68.9h-49a.7.7 0 0 1-.6-.7V3.5c0-.4.3-.7.7-.7h48.9c.4 0 .7.3.7.7V54c0 .4-.3.7-.7.7s-.7-.3-.7-.7V4.2H52.3v63.3h47.5V62c0-.4.3-.7.7-.7s.7.3.7.7v6.2c0 .4-.4.7-.7.7z\"/><path fill=\"#C9CDD4\" d=\"M76.1 22H57.4a.5.5 0 0 1-.5-.4V10.5c0-.3.2-.5.5-.5h18.7c.3 0 .5.2.5.5v11c0 .3-.2.6-.5.6z\"/><defs><path id=\"a\" d=\"M57 10h19.6v12.1H57z\"/></defs><clippath id=\"b\"><use xlink:href=\"#a\" overflow=\"visible\"/></clippath><g clip-path=\"url(#b)\"><path fill=\"#A2AAB6\" d=\"M56.1 24l7.5-10.6c.2-.3.6-.3.7 0L71.5 25\"/><path fill=\"#A2AAB6\" d=\"M64.5 27L72 16.5c.2-.2.6-.2.7 0L79.8 28\"/></g><path fill=\"#7C8797\" d=\"M92 58.8H59c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h33c.4 0 .8.4.8.8s-.4.8-.8.8zM93 45.8H59c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h34c.4 0 .8.4.8.8s-.4.8-.8.8zM90 52.8H59c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h31c.4 0 .8.4.8.8s-.4.8-.8.8z\"/><g><path fill=\"#7C8797\" d=\"M91 38.8H59c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h32c.4 0 .8.4.8.8s-.4.8-.8.8z\"/></g><g><path fill=\"#7C8797\" d=\"M68.5 31.8H59c-.4 0-.7-.4-.7-.8s.3-.8.7-.8h9.5c.5 0 .8.4.8.8s-.3.8-.8.8z\"/></g></svg>";
},"useData":true});