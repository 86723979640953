var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.16 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M8.58 1.42c-2.21 0-4.21.95-5.59 2.47A7.52 7.52 0 0 0 1 9c0 1.53.46 2.96 1.24 4.15a7.57 7.57 0 0 0 11.91.98 7.56 7.56 0 0 0-.12-10.39 7.53 7.53 0 0 0-5.45-2.32zM5.69 15.34l.15-.06.75-.22.83-.24c.16-.05.44-.14.69-.42.08-.11.11-.35.12-.47.09.01.19.04.28.04h.07c.16 0 .31-.02.47-.06.01.14.05.38.14.51.23.26.5.35.72.42l.75.23.79.24.04.01a6.92 6.92 0 0 1-5.8.02zm6.54-5.88a.77.77 0 0 0-.46-.27v-.27c0-1.39-1.64-1.44-2.96-1.48-1.5-.04-1.86-.36-2.21-.8a.61.61 0 0 0-.47-.23l-.08.01a.59.59 0 0 0-.47.36c-.3.7-.32 1.64-.3 2.23a.69.69 0 0 0-.38.29.97.97 0 0 0-.09.91c.11.32.31.53.53.64.11.8.52 1.52 1.06 2.06-.73-.11-1.9-.41-2.19-1.22-.32-.87-.55-3.88.76-5.82.79-1.17 2-1.76 3.6-1.76h.1c1.59 0 2.79.58 3.57 1.74 1.3 1.92 1.04 4.96.72 5.85-.3.84-1.51 1.13-2.26 1.23.51-.52.89-1.19 1.03-1.88.27-.12.49-.38.63-.74l.03-.08c.07-.39-.05-.64-.16-.77zm-6.31 1.23l-.02-.32-.1.01c-.08 0-.32-.04-.44-.37-.08-.27.04-.44.15-.44.16 0 .41.14.44.31l-.04-.38c0-.02-.17-1.57.22-2.49.53.67 1.13.99 2.67 1.03 1.43.04 2.38.12 2.38.88v1.14c.22-.24.26-.22.32-.24l.15-.03c.12 0 .19.09.14.31-.12.3-.29.44-.4.44h-.08c-.04 0-.06-.01-.11-.05l-.02.2c-.12 1.33-1.42 2.68-2.59 2.68h-.08c-1.04 0-2.46-1.26-2.59-2.68zm6.21 4.3c-.02-.02-.03-.06-.06-.07a2.03 2.03 0 0 0-.43-.17l-.8-.24-.79-.24c-.12-.04-.25-.08-.36-.19l-.04-.29-.03-.06.2-.11.44-.03c.82-.05 2.77-.32 3.26-1.68.36-1 .65-4.26-.79-6.39-.9-1.33-2.26-2-4.06-2h-.1c-1.81 0-3.19.68-4.1 2.03-1.45 2.15-1.18 5.38-.82 6.36.48 1.32 2.37 1.61 3.17 1.67l.32.02.14.01h.01c.13.07.25.13.38.18l-.02.04-.04.26c-.11.1-.25.14-.42.2l-.76.22-.75.22c-.17.05-.33.11-.47.18-.06.03-.09.08-.11.14a7.11 7.11 0 0 1-2.33-2.2A6.91 6.91 0 0 1 1.6 9c0-1.79.67-3.42 1.78-4.65a6.95 6.95 0 0 1 5.2-2.33c1.99 0 3.79.84 5.06 2.18A6.94 6.94 0 0 1 15.56 9a6.97 6.97 0 0 1-3.43 5.99z\"/></svg>";
},"useData":true});