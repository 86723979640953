var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M13.46 8a3.21 3.21 0 0 0-3.2 3.2v1.49C9.12 12.7 8 12.95 8 15.3v2.93a.38.38 0 0 0 .75 0v-2.93c0-1.67.5-1.85 1.5-1.87v11.72c0 1.23.7 2.28 1.7 2.82v.81c0 .95.78 1.73 1.73 1.73h2.45c.95 0 1.73-.78 1.73-1.73v-.42h3v.42c0 .95.77 1.73 1.72 1.73h2.45c.95 0 1.73-.78 1.73-1.73v-.81c1-.54 1.7-1.6 1.7-2.82V13.44c1 .02 1.5.2 1.5 1.88v2.92a.38.38 0 0 0 .75 0v-2.92c0-2.37-1.13-2.6-2.25-2.63v-1.48A3.21 3.21 0 0 0 25.25 8zm0 .75h11.79a2.46 2.46 0 0 1 2.46 2.46v.17h-16.7v-.17a2.46 2.46 0 0 1 2.45-2.46zM11 12.13h7.98v7.4H11zm8.73 0h7.98v7.4h-7.98zM11 20.27h16.71v4.9a2.46 2.46 0 0 1-2.46 2.45H13.46A2.46 2.46 0 0 1 11 25.16zm3.9 1.45a2.01 2.01 0 0 0 0 4.02 2.01 2.01 0 0 0 0-4.02zm8.9 0a2.01 2.01 0 0 0 0 4.02 2.01 2.01 0 0 0 2.02-2c0-1.12-.9-2.02-2.01-2.02zm-8.9.75a1.26 1.26 0 1 1 0 2.52 1.26 1.26 0 0 1 0-2.52zm8.9 0c.7 0 1.27.57 1.27 1.26a1.26 1.26 0 0 1-2.53 0c0-.7.57-1.26 1.27-1.26zm-11.1 5.9h4.4v.42a.97.97 0 0 1-.97.98h-2.45a.97.97 0 0 1-.97-.98zm8.9 0H26v.42a.98.98 0 0 1-.97.98h-2.45a.97.97 0 0 1-.97-.98z\"/></svg>";
},"useData":true});