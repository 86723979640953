var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 18 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M1.48 4.89h5.19c.23.93 1.06 1.63 2.06 1.63s1.84-.7 2.06-1.63h5.44c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H10.8a2.13 2.13 0 0 0-2.06-1.63c-1 0-1.84.7-2.06 1.63h-5.2c-.28 0-.5.22-.5.5s.22.5.5.5zm7.25-1.63a1.13 1.13 0 1 1 0 2.26 1.13 1.13 0 0 1 0-2.26zm7.79 5.27h-1.61c-.23-.93-1.06-1.63-2.06-1.63s-1.84.7-2.06 1.63H1.48c-.28 0-.5.22-.5.5s.22.5.5.5h9.31c.23.93 1.06 1.63 2.06 1.63s1.84-.7 2.06-1.63h1.61c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zm-3.67 1.63c-.62 0-1.13-.51-1.13-1.13 0-.62.51-1.13 1.13-1.13s1.13.51 1.13 1.13c0 .62-.51 1.13-1.13 1.13zm3.38 3.01H7.86c-.23-.93-1.06-1.63-2.06-1.63s-1.84.7-2.06 1.63H1.48c-.28 0-.5.22-.5.5s.22.5.5.5h2.26a2.12 2.12 0 0 0 4.12 0h8.37c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM5.8 14.8c-.62 0-1.13-.51-1.13-1.13s.51-1.13 1.13-1.13 1.13.51 1.13 1.13-.51 1.13-1.13 1.13z\"/></svg>";
},"useData":true});