var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 16 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M14.5 2.24h-3.01v-.59c0-.28-.22-.5-.5-.5s-.5.22-.5.5v.59h-2v-.59c0-.28-.22-.5-.5-.5s-.5.22-.5.5v.59h-2v-.59c0-.28-.22-.5-.5-.5s-.5.22-.5.5v.59H1.5a.5.5 0 0 0-.5.5v12.9c0 .28.22.5.5.5h13a.5.5 0 0 0 .5-.5V2.74a.5.5 0 0 0-.5-.5zm-.5 12.9H2V3.24h2.48v.59c0 .28.22.5.5.5s.5-.22.5-.5v-.59h2v.59c0 .28.22.5.5.5s.5-.22.5-.5v-.59h2v.58c0 .28.22.5.5.5s.5-.22.5-.5v-.58H14v11.9z\"/><path fill=\"currentColor\" d=\"M3.92 7.27c-.28 0-.5.22-.5.5s.22.5.5.5h8.09c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H3.92zm5.26 3H3.92c-.28 0-.5.22-.5.5s.22.5.5.5h5.26c.28 0 .5-.22.5-.5s-.22-.5-.5-.5z\"/></svg>";
},"useData":true});