var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.99 10C12.03 10 8 13.36 8 17.49a6.8 6.8 0 0 0 2.32 5.02l-.83 2.91a.38.38 0 0 0 .49.46l3.93-1.46.09.03c.87.25 1.78.52 2.99.52a9.7 9.7 0 0 0 6.88-2.65 6.82 6.82 0 0 0 2.1-4.83c0-4.13-4.02-7.49-8.98-7.49zm0 .75c4.54 0 8.24 3.02 8.24 6.74 0 1.56-.67 3.09-1.9 4.3A9.05 9.05 0 0 1 17 24.23c-1.1-.01-1.96-.26-2.78-.5l-.21-.06a.4.4 0 0 0-.23 0l-3.36 1.25.7-2.43a.38.38 0 0 0-.12-.38 6.1 6.1 0 0 1-2.24-4.62c0-3.72 3.7-6.74 8.24-6.74zm10.21 3.89a.37.37 0 0 0-.25.13.37.37 0 0 0 .03.52 5.98 5.98 0 0 1 2.13 4.47c0 1.69-.77 3.3-2.19 4.52a.37.37 0 0 0-.12.38l.59 2.27-3.27-1.06a.35.35 0 0 0-.21 0c-1.12.32-2.12.5-2.87.5a9.71 9.71 0 0 1-2.14-.24.37.37 0 0 0-.45.28.38.38 0 0 0 .29.45c.74.17 1.52.25 2.3.25.8 0 1.8-.16 2.96-.5l3.8 1.24a.37.37 0 0 0 .38-.08.38.38 0 0 0 .1-.37l-.7-2.7a6.68 6.68 0 0 0 2.28-4.94c0-1.87-.85-3.66-2.39-5.03a.37.37 0 0 0-.27-.1z\"/></svg>";
},"useData":true});