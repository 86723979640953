var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-spot__svg--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":90}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 165 92\" fill=\"none\" class=\"m-spot__svg "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":97}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"svgClass") || (depth0 != null ? lookupProperty(depth0,"svgClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"svgClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":98},"end":{"line":1,"column":110}}}) : helper)))
    + "\"><g clip-path=\"url(#a)\"><path d=\"M2.5 101c7.8-29 74-85.1 133-70.7 24.3 6 78.6 30 86.1 60.6 17.8 72.5-38.6 113-78.6 117.2-53.2 5.5-120.8-15-128.7-32.7-7-15.4-19.7-45.6-11.8-74.5z\" fill=\"#DAEFC0\"/><path d=\"M63.8 45.4V.8c0-.4.4-.8.8-.8H117c.5 0 .8.3.8.8v43.4l6 1.2-3.3 34H60L58 74.7V44.2l5.7 1.2z\" fill=\"#fff\"/><path d=\"M63.8 38.4L58 42.7l5.8 3.1v-7.4zM117.3 36.8l6.8 5.9-7.2 3.4.4-9.3z\" fill=\"#A2AAB6\"/><path d=\"M123.5 79V43.5c0-.3-.3-.5-.5-.4l-3 1.3s1.4 32.7-13.2 34.5h16.7z\" fill=\"#E3E6E9\"/><path d=\"M123.7 71v4c0 2.7-2 4.9-4.5 4.9H62.5c-2.5 0-4.5-2.2-4.5-5V43.2l5.8-4.9M123.7 62.7V65M116.8 36.7l6.9 6v15\" stroke=\"#7C8797\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M113.5 48.5V0h5.2c1 0 1.1.4 1.1 1v43.7l-6.3 3.8z\" fill=\"#E3E6E9\"/><path d=\"M63.8 45.4V.8c0-.4.4-.8.8-.8H117c.5 0 .8.3.8.8v44.5\" stroke=\"#7C8797\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M86.7 22.7l.3.3\" stroke=\"#377800\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M92.3 24.9s6.8-5.2 6.8-10c0-5-6-5.5-6.8-.1-.6-5.3-7-5-7 .4 0 5.3 7 9.7 7 9.7z\" fill=\"#8BC72A\"/><path d=\"M96.5 14.7s1.2 1.6-1.5 5.2c-1.5 1.8-3.6 3.5-3.6 3.5L89.7 22s1.4-.3 4.5-3.4c2.6-2.7 2.3-4 2.3-4z\" fill=\"#619F00\"/><path d=\"M88.7 24.4l2 1.5s8-5.7 8-11c0-5.4-7.2-6-8-.1-.6-5.8-8-5.4-8 .4 0 2.2 1.1 4.2 2.5 5.8\" stroke=\"#377800\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M77.5 33h-3.7m36 0H81.2m7.2 7.7H73.8m36 0h-18M123.5 42.8L91.4 58.3 65.2 46l-7-3.3\" stroke=\"#7C8797\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g><defs><clippath id=\"a\"><path fill=\"#fff\" d=\"M0 0h165v92H0z\"/></clippath></defs></svg>";
},"useData":true});