var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 18 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M16.48 2.76H1.51a.5.5 0 0 0-.5.5v9.86c0 .28.23.51.51.51H8.5v1.27H6.23c-.28 0-.5.22-.5.5s.22.5.5.5h5.54c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H9.5v-1.27h6.98c.28 0 .51-.23.51-.51V3.27a.51.51 0 0 0-.51-.51zm-.49 1V7.1h-2.22l-2.52-1.99a.51.51 0 0 0-.59-.02L9.15 6.1 7.31 4.5a.5.5 0 0 0-.63-.01L3.26 7.1H2.01V3.76h13.98zM8.78 7.1H4.9l2.06-1.58L8.78 7.1zm1.52 0l-.37-.32.98-.66 1.24.98H10.3zm-8.29 5.53V8.1h13.97v4.52H2.01z\"/><circle fill=\"currentColor\" cx=\"13.74\" cy=\"5.06\" r=\".66\"/></svg>";
},"useData":true});