var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 18 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M16.35 4.13H12v-.54a1.6 1.6 0 0 0-1.65-1.56h-2.7c-.91 0-1.65.7-1.65 1.56v.54H1.66a.7.7 0 0 0-.7.7v9.78c0 .39.31.7.7.7h14.69a.7.7 0 0 0 .7-.7V4.83a.7.7 0 0 0-.7-.7zM7 3.59c0-.3.3-.56.65-.56h2.7c.35 0 .65.25.65.56v.54H7v-.54zm9.05 10.71H1.96V5.13h14.09v9.17z\"/><path fill=\"currentColor\" d=\"M8.74 12.39a.52.52 0 0 0 .55-.02c.26-.19 2.58-1.88 2.58-3.67 0-1.07-.77-1.69-1.52-1.74-.48-.01-.97.14-1.34.53a1.64 1.64 0 0 0-1.28-.51c-.79.05-1.59.69-1.59 1.85 0 1.96 2.5 3.5 2.6 3.56zm-.96-4.41h.03c.28 0 .62.2.69.77a.5.5 0 0 0 .5.44h.01a.5.5 0 0 0 .5-.43c.08-.62.47-.8.78-.79.27.02.59.22.59.74 0 .95-1.18 2.09-1.88 2.64-.69-.49-1.84-1.52-1.84-2.52-.02-.6.33-.83.62-.85z\"/></svg>";
},"useData":true});