var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 15.49 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M13.99 2.12H9.3c-.28 0-.5.22-.5.5s.22.5.5.5h3.48L6.34 9.56a.5.5 0 0 0 0 .71.48.48 0 0 0 .7 0l6.44-6.44v3.48c0 .28.22.5.5.5s.5-.22.5-.5V2.62a.5.5 0 0 0-.49-.5z\"/><path fill=\"currentColor\" d=\"M13 9.28a.5.5 0 0 0-.5.5v4.51c0 .32-.24.59-.52.59H2.52c-.28 0-.52-.27-.52-.59V4.97c0-.32.24-.59.52-.59H6.7c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H2.52C1.68 3.38 1 4.09 1 4.97v9.32c0 .88.68 1.59 1.52 1.59h9.45c.84 0 1.52-.71 1.52-1.59V9.78a.49.49 0 0 0-.49-.5z\"/></svg>";
},"useData":true});