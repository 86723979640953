var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M13.55 9a6.04 6.04 0 0 0-4.35 1.76 6.17 6.17 0 0 0-.97 7.34c.1.18.33.24.51.14a.37.37 0 0 0 .15-.5 5.42 5.42 0 0 1 .84-6.45 5.43 5.43 0 0 1 7.66.09l1.72 1.7a.47.47 0 0 0 .27.12.38.38 0 0 0 .29-.11l1.72-1.71a5.42 5.42 0 0 1 7.66-.09 5.43 5.43 0 0 1-.08 7.67l-3.89 3.87-.53.52-5.16 5.14a2132.17 2132.17 0 0 1-6.41-6.35.38.38 0 1 0-.53.53l1.68 1.67.39.38 4.62 4.57c.06.06.16.09.25.09a.37.37 0 0 0 .25-.1c.06-.04 2.5-2.47 5.02-4.98l.46-.45.45-.46 3.93-3.9a6.18 6.18 0 0 0 .08-8.73 6.18 6.18 0 0 0-8.72.09l-1.47 1.46-1.47-1.46A6.27 6.27 0 0 0 13.55 9zm5.78 6.14a.37.37 0 0 0-.35.26l-1.88 6.14-1.84-3.72a.37.37 0 0 0-.3-.2.39.39 0 0 0-.33.14l-1.5 2.02H6.38a.38.38 0 0 0 0 .75h6.96c.12 0 .23-.06.3-.15l1.23-1.67 1.98 4.02a.37.37 0 0 0 .7-.06l1.83-5.97 1.3 3.61c.06.15.2.25.36.25h2.68a.38.38 0 0 0 0-.75H21.3l-1.6-4.42c-.05-.16-.2-.27-.36-.25z\"/></svg>";
},"useData":true});