var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.71 9a.37.37 0 0 0-.36.16L15.1 10.9l-2.16-.14a.38.38 0 0 0-.4.3l-.35 2.14-2 .8a.38.38 0 0 0-.22.45l.6 2.06-1.47 1.6a.38.38 0 0 0 0 .5l1.44 1.6-.63 2.08a.38.38 0 0 0 .21.46l2 .82.33 2.13a.39.39 0 0 0 .4.32L15 25.9l1.23 1.78c.11.16.33.2.5.11l1.87-1.04 1.88 1.08c.06.03.12.05.18.05a.37.37 0 0 0 .3-.16l1.26-1.78 2.16.15a.39.39 0 0 0 .4-.3l.35-2.12 2-.8a.38.38 0 0 0 .22-.45l-.6-2.09 1.47-1.57a.37.37 0 0 0 0-.5l-1.44-1.63.63-2.05a.38.38 0 0 0-.21-.46l-2-.83-.33-2.13c-.03-.19-.2-.33-.39-.31l-2.16.12-1.22-1.79a.37.37 0 0 0-.49-.12l-1.9 1.05-1.87-1.06a.38.38 0 0 0-.13-.05zm.06.87l1.76 1a.38.38 0 0 0 .37 0l1.78-.98 1.14 1.67a.37.37 0 0 0 .33.17l2.03-.12.31 2a.37.37 0 0 0 .23.3l1.88.77-.6 1.93a.38.38 0 0 0 .08.36l1.36 1.53-1.39 1.48a.37.37 0 0 0-.08.36l.56 1.96-1.87.75a.38.38 0 0 0-.23.28l-.34 2-2.04-.15a.37.37 0 0 0-.33.16L20.55 27 18.8 26a.38.38 0 0 0-.37 0l-1.76.98-1.16-1.68a.35.35 0 0 0-.33-.16l-2.03.13-.31-2a.38.38 0 0 0-.23-.3l-1.88-.77.6-1.95a.37.37 0 0 0-.08-.36l-1.36-1.5 1.39-1.5a.38.38 0 0 0 .08-.36l-.57-1.95 1.88-.74a.38.38 0 0 0 .23-.29l.34-2 2.03.14a.38.38 0 0 0 .34-.16zM15.88 14a1.63 1.63 0 0 0-1.62 1.63A1.63 1.63 0 1 0 15.88 14zm5.44.64a.37.37 0 0 0-.25.15l-5.3 6.85a.37.37 0 0 0 .3.6c.1 0 .22-.04.3-.14l5.3-6.85a.38.38 0 0 0-.35-.6zm-5.44.11a.88.88 0 0 1 0 1.75.88.88 0 0 1 0-1.75zm5.59 4.85a1.63 1.63 0 0 0 0 3.26 1.63 1.63 0 0 0 0-3.26zm0 .75a.88.88 0 0 1 0 1.76.88.88 0 0 1 0-1.76z\"/></svg>";
},"useData":true});