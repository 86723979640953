var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M14.8 8c-1 0-1.8.8-1.8 1.8v16.99c0 .99.8 1.79 1.8 1.79h8.51c1 0 1.8-.8 1.8-1.79v-17c0-.99-.81-1.8-1.8-1.8zm0 .75h8.51c.58 0 1.04.47 1.04 1.04v1.02h-10.6V9.8c0-.57.47-1.04 1.04-1.04zm-1.05 2.81h10.6v13h-10.6zm3.94.45a.37.37 0 0 0-.26.11l-2.96 2.95a.38.38 0 1 0 .27.64.38.38 0 0 0 .27-.1l2.95-2.96a.38.38 0 0 0-.27-.64zm1.68.29a.37.37 0 0 0-.26.1l-4.54 4.54a.38.38 0 0 0 0 .54.38.38 0 0 0 .53 0l4.54-4.54a.38.38 0 0 0-.26-.64zm-1.55 3.47a.38.38 0 0 0-.27.1l-2.9 2.92a.38.38 0 0 0 .52.53l2.91-2.91a.37.37 0 0 0-.26-.64zm-4.07 9.54h10.6v1.48c0 .57-.46 1.04-1.04 1.04H14.8a1.04 1.04 0 0 1-1.04-1.04zm5.3.4a.7.7 0 0 0-.7.7.7.7 0 0 0 1.4 0 .7.7 0 0 0-.7-.7z\"/></svg>";
},"useData":true});