var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.16 9a.37.37 0 0 0-.28.07 1.76 1.76 0 0 0-.63 1.38c0 .44.42 1.09.54 1.28.2.3.42.73.42.87 0 .5-.31.75-.33.77a.37.37 0 0 0 .45.6c.02-.02.63-.48.63-1.37 0-.44-.42-1.1-.54-1.29a2.7 2.7 0 0 1-.42-.86c0-.5.32-.77.33-.77a.38.38 0 0 0-.17-.68zm4.34 0a.38.38 0 0 0-.28.07 1.76 1.76 0 0 0-.64 1.38c0 .44.42 1.09.55 1.28.22.33.42.74.42.87 0 .5-.31.75-.34.77a.37.37 0 0 0 .45.6c.03-.02.64-.48.64-1.37 0-.44-.42-1.1-.55-1.29-.2-.29-.42-.72-.42-.86 0-.5.32-.77.33-.77A.38.38 0 0 0 19.5 9zM8.37 15.58a.38.38 0 0 0-.37.37v3.47c0 3.6 2.46 6.78 5.16 8.43H8.98a.38.38 0 0 0 0 .75h16.8a.38.38 0 0 0 0-.75H21.3a12.31 12.31 0 0 0 3.16-2.7c.54-.11 3.59-.83 4.85-3.74.79-1.8.6-3.3.59-3.37a.37.37 0 0 0-.37-.32h-2.76v-1.77a.38.38 0 0 0-.37-.37zm.38.75h17.26v3.09c0 3.65-3.21 7.14-6.32 8.4a.39.39 0 0 0-.07.03H14.8c-2.98-1.22-6.06-4.7-6.06-8.43zm18.01 2.14h2.4a6.55 6.55 0 0 1-.55 2.65 5.86 5.86 0 0 1-3.4 3.02 8.63 8.63 0 0 0 1.55-4.72z\"/></svg>";
},"useData":true});