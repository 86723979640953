var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 16.43 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M14.39 7.43a.5.5 0 0 0-.33-.41l-1.81-.64c.2-.47.36-.93.41-1.33a2.7 2.7 0 0 0-.65-2.12 2.89 2.89 0 0 0-2.22-.96 2.82 2.82 0 0 0-2.86 3.06c.04.39.18.85.38 1.31l-1.45.59-3.65-1.45-.07-.01-.08-.03h-.02l-.12.02-.07.02a.41.41 0 0 0-.11.07l-.05.03-.07.08-.05.08-.01.02-.01.08a.14.14 0 0 0-.02.07L1 13.82v.03l.01.1.02.09.05.08.06.08.07.06a.4.4 0 0 0 .09.05l.02.01L5.7 15.9a.5.5 0 0 0 .34 0l4.11-1.52 4.63 1.48a.5.5 0 0 0 .65-.54l-1.04-7.89zM8.41 3.56a1.8 1.8 0 0 1 1.39-.59c.76 0 1.22.33 1.46.62.32.37.48.86.41 1.33-.14 1.02-1.18 2.72-1.87 3.72-.7-1.01-1.78-2.73-1.86-3.7-.05-.5.12-1.01.47-1.38zm-5.93 3.1l2.89 1.15v6.91l-3.34-1.21.45-6.85zm8.13 6.82l-.15-2.31a.5.5 0 0 0-.53-.47.5.5 0 0 0-.47.53l.14 2.28-3.24 1.2v-6.9l1.39-.56A21.16 21.16 0 0 0 9.42 9.8c.09.12.24.2.4.2.16 0 .3-.08.4-.2.06-.08.91-1.23 1.6-2.51l1.62.58.89 6.8-3.72-1.19z\"/><path fill=\"currentColor\" d=\"M10.62 4.82c0-.44-.36-.8-.8-.8s-.8.36-.8.8.36.8.8.8.8-.36.8-.8z\"/></svg>";
},"useData":true});