var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 14.11 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M6.1 7.84L4.58 5.09c-.06-.07-.13-.12-.23-.12H1.76a.25.25 0 0 0-.22.13.23.23 0 0 0 0 .25L3 7.97l-1.97 3.17a.23.23 0 0 0 0 .25c.04.08.13.13.22.13h2.59c.09 0 .16-.05.22-.13l2.03-3.3a.31.31 0 0 0 .01-.25zm6.97-4.77a.28.28 0 0 0-.22-.12h-2.74a.26.26 0 0 0-.21.12l-4.07 7.06a.23.23 0 0 0 0 .25l2.62 4.55c.05.08.13.13.22.13h2.74c.09 0 .18-.05.22-.13a.23.23 0 0 0 0-.25l-2.55-4.42 3.99-6.93a.24.24 0 0 0 0-.26z\"/></svg>";
},"useData":true});