var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.12 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M8.57 1.42c-2.03 0-3.89.81-5.25 2.12a7.57 7.57 0 0 0-.61 10.25 7.56 7.56 0 0 0 11.61.14A7.55 7.55 0 0 0 16.15 9c0-2.13-.89-4.06-2.31-5.44a7.5 7.5 0 0 0-5.27-2.14zM5.48 15.24l.06-.03.79-.23.8-.23c.16-.05.43-.14.68-.41.07-.1.11-.32.12-.45.16.03.32.06.48.06h.08c.13 0 .26-.02.39-.05.01.13.05.38.15.52.23.25.5.33.71.4l1.15.31.66.18c-.9.43-1.91.68-2.97.68a6.99 6.99 0 0 1-3.1-.75zm6.59-7.59c-.13.13-.16.34-.2.6a.6.6 0 0 0-.56-.4h-.02a.41.41 0 0 0-.16.03c-.2.06-.53.13-.95.13L9.85 8a6.46 6.46 0 0 1-3.09-1.1.6.6 0 0 0-.34-.11.6.6 0 0 0-.23.05.59.59 0 0 0-.37.45l-.01.05a.57.57 0 0 0-.22-.04.6.6 0 0 0-.6.6v.83c-.26-1.08-.25-1.92.06-2.41a.96.96 0 0 1 .45-.39.37.37 0 0 0 .17-.16c.11-.25.6-1.11 2.13-1.24a4.14 4.14 0 0 1 2.62.61c.4.22.78.42 1.24.43.34.01.7-.06.88-.11.09.61.08 1.65-.47 2.19zM5.14 9.33a.1.1 0 0 1 .08.04l.37.5V7.9l.76.48.01-.36c.01-.23.03-.46.06-.63a7 7 0 0 0 3.39 1.2l.38.02c.5 0 .88-.08 1.13-.16l-.02 1.42.38-.5a.09.09 0 0 1 .08-.04c.13 0 .33.23.21.52-.12.3-.31.46-.42.46l-.23-.05-.02.23c-.14 1.51-1.69 2.86-2.81 2.86h-.08c-1.26 0-2.68-1.44-2.81-2.86l-.03-.23-.23.05c-.1 0-.29-.16-.41-.46-.12-.29.07-.52.21-.52zm7.16 5.55a126.6 126.6 0 0 0-2.43-.66c-.12-.04-.25-.08-.36-.18a7.56 7.56 0 0 1-.04-.26c0-.03-.02-.04-.03-.06a4.2 4.2 0 0 0 2.41-2.88c.28-.12.53-.4.68-.78.13-.32.09-.68-.09-.95l-.1-.11c.07-.22.11-.46.14-.65l.03-.28c.92-.91.67-2.52.61-2.83l-.04-.2a.34.34 0 0 0-.13-.2c-.07-.04-.15-.05-.23-.04l-.21.05c-.15.04-.52.11-.82.12-.32-.01-.61-.17-.98-.37-.63-.34-1.48-.8-2.95-.68-1.7.15-2.37 1.08-2.59 1.5a1.5 1.5 0 0 0-.62.58c-.43.68-.46 1.73-.09 3.11a1 1 0 0 0-.09.95c.15.38.4.67.68.78a4.2 4.2 0 0 0 2.31 2.86l-.02.04-.04.26c-.11.1-.25.14-.42.2l-.72.21-.79.23c-.17.05-.33.11-.47.18-.03.01-.03.04-.05.06a6.93 6.93 0 0 1-1.71-1.53A6.9 6.9 0 0 1 1.6 9c0-1.94.8-3.7 2.08-4.96a6.97 6.97 0 1 1 8.62 10.84z\"/></svg>";
},"useData":true});