var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.58 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M14.77 9.63h-2.68c-.4-.38-.93-.63-1.56-.63h-3.5c-.61 0-1.13.25-1.54.63H2.82c-1.1 0-1.84 1.01-1.84 1.95 0 .76.34 2.11.35 2.17a.5.5 0 0 0 .48.38l.12-.01a.5.5 0 0 0 .36-.61c-.09-.36-.32-1.4-.32-1.93 0-.45.35-.95.84-.95H4.8c-.13.33-.2.67-.2 1.01 0 1.08.48 3.05.5 3.13a.5.5 0 0 0 .49.38l.12-.01a.5.5 0 0 0 .37-.6c0-.02-.47-1.93-.47-2.89 0-.79.57-1.64 1.43-1.64h3.5c.88 0 1.43.85 1.43 1.64 0 .96-.46 2.88-.47 2.89a.5.5 0 0 0 .37.6.5.5 0 0 0 .6-.37c.02-.08.5-2.05.5-3.13 0-.34-.07-.68-.2-1.01h2c.5 0 .84.5.84.95 0 .53-.23 1.57-.32 1.93a.5.5 0 0 0 .36.61l.12.01a.5.5 0 0 0 .48-.38c.01-.06.35-1.41.35-2.17.01-.94-.73-1.95-1.83-1.95zm-5.99-.95a2.92 2.92 0 1 0 0-5.85 2.92 2.92 0 0 0 0 5.85zm0-4.83a1.92 1.92 0 0 1 0 3.84 1.92 1.92 0 0 1 0-3.84z\"/><path fill=\"currentColor\" d=\"M3.49 9.39c1.2 0 2.17-.96 2.17-2.14s-.97-2.14-2.17-2.14-2.17.96-2.17 2.14.98 2.14 2.17 2.14zm0-3.28c.65 0 1.17.51 1.17 1.14 0 .63-.53 1.14-1.17 1.14s-1.17-.51-1.17-1.14c0-.63.53-1.14 1.17-1.14zm10.58 3.28c1.2 0 2.17-.96 2.17-2.14s-.98-2.14-2.17-2.14-2.17.96-2.17 2.14.97 2.14 2.17 2.14zm0-3.28c.65 0 1.17.51 1.17 1.14 0 .63-.53 1.14-1.17 1.14s-1.17-.51-1.17-1.14a1.15 1.15 0 0 1 1.17-1.14z\"/></svg>";
},"useData":true});