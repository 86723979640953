var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M19.8 9a2.2 2.2 0 0 0-2.2 2.19c0 .08.03.16.04.24-3.45.97-5.96 3.64-6.54 6.8a24.3 24.3 0 0 0-1.2-.08c-.43-.5-1.18-.93-2.53-.94a.38.38 0 0 0-.37.38c0 .2.21.35.38.37.6 0 1.04.1 1.37.25-.54.1-.95.36-1.22.88-.3.56-.2 1.27.26 1.76a1.48 1.48 0 0 0 1.68.38c1.24-.52 1-1.68.92-2.06a2.09 2.09 0 0 0-.07-.25l.68.06a8.17 8.17 0 0 0-.03.65c0 1.67.53 3.29 1.54 4.68-.04.13-.07.26-.07.4v3.55c0 .75.61 1.36 1.36 1.36h1.3a1.36 1.36 0 0 0 1.36-1.36v-.84c2.1.88 4.54 1.04 6.74.44v.4c0 .75.6 1.36 1.35 1.36h1.3a1.36 1.36 0 0 0 1.36-1.36V25.7a8.62 8.62 0 0 0 2.16-3.02h.91c.75 0 1.36-.6 1.36-1.36v-1.96c0-.75-.61-1.36-1.36-1.36h-.47a8.43 8.43 0 0 0-2.86-4.66 288.56 288.56 0 0 0-.29-3.82.38.38 0 0 0-.47-.33 4.44 4.44 0 0 0-2.82 2.26 10.21 10.21 0 0 0-1.4-.3A2.19 2.19 0 0 0 19.8 9zm0 .75a1.44 1.44 0 0 1 1.43 1.44c0 .5-.28.98-.72 1.24a3.5 3.5 0 0 0-.7-.07h-.03c-.23 0-.44.03-.64.07h-.06a1.44 1.44 0 0 1-.73-1.24 1.44 1.44 0 0 1 1.44-1.44zm6.15.3c.07.79.2 2.42.27 3.5 0 .1.05.2.14.26a7.66 7.66 0 0 1 2.77 4.62c.03.18.2.31.37.31h.78c.34 0 .6.28.6.61v1.96a.6.6 0 0 1-.6.61h-1.16a.38.38 0 0 0-.35.24 7.84 7.84 0 0 1-2.18 3.08.38.38 0 0 0-.13.28v2.74a.6.6 0 0 1-.6.61h-1.3a.6.6 0 0 1-.61-.6v-.9a.38.38 0 0 0-.16-.31.38.38 0 0 0-.33-.05 9.8 9.8 0 0 1-7.2-.51.37.37 0 0 0-.55.34v1.42a.6.6 0 0 1-.6.61h-1.3a.6.6 0 0 1-.62-.6V24.7a.6.6 0 0 1 .07-.28.38.38 0 0 0-.03-.4 7.24 7.24 0 0 1-1.5-4.4c0-3.43 2.5-6.46 6.12-7.48.1.2.23.4.4.56a3.69 3.69 0 0 0-.66.4l-.07.05v.01a.38.38 0 0 0 .5.56 2.81 2.81 0 0 1 .97-.5l.12-.04a2.77 2.77 0 0 1 .76-.07 3.23 3.23 0 0 1 1.54.46.38.38 0 0 0 .39-.64 4.29 4.29 0 0 0-.46-.24c.23-.23.4-.5.51-.81.54.07 1.08.19 1.6.35a.38.38 0 0 0 .44-.2c.48-.99 1.16-1.65 2.06-2zm-1.8 6.39a1.61 1.61 0 0 0 0 3.22 1.61 1.61 0 0 0 0-3.22zm0 .75c.48 0 .87.38.87.86a.86.86 0 1 1-.86-.86zM9.5 18.89c.08.15.13.3.15.43.15.7.02 1.01-.47 1.22a.73.73 0 0 1-.83-.2c-.2-.21-.33-.58-.16-.9.21-.4.6-.54 1.3-.55z\"/></svg>";
},"useData":true});