var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M19.91 7a.38.38 0 0 0-.35.5l2.56 7.7h-11.8a2.24 2.24 0 0 0-2.2-1.87h-.74a.38.38 0 0 0 0 .75h.74c.82 0 1.49.67 1.49 1.49v.74a6.34 6.34 0 0 0 6.33 6.34h-.07c.85 1.32 2 2.44 3.34 3.3a11.91 11.91 0 0 1-5.18 1.16.38.38 0 0 0 0 .75c2.13 0 4.18-.52 5.95-1.47a12.62 12.62 0 0 0 5.95 1.47.38.38 0 0 0 0-.75 11.9 11.9 0 0 1-5.18-1.17 10.96 10.96 0 0 0 3.28-3.2l.06-.1h.41a6.34 6.34 0 0 0 6.34-6.33v-.74a8.55 8.55 0 0 0-3.16-6.64l-.04-.03a.17.17 0 0 0-.02-.02A8.52 8.52 0 0 0 22.27 7zm.52.75h1.84c1.73 0 3.33.56 4.63 1.52l-4.13 5.5zm7.05 2a7.8 7.8 0 0 1 2.6 5.44h-6.69zm-17.13 6.2h19.73v.36a5.6 5.6 0 0 1-5.58 5.59h-8.56a5.59 5.59 0 0 1-5.58-5.59zm6.42 6.7h6.42a10.34 10.34 0 0 1-3.21 2.88 10.35 10.35 0 0 1-3.2-2.89zm8.97 1.7a3.16 3.16 0 0 0-2.9 1.74.37.37 0 0 0 .16.5c.18.1.41.02.5-.16a2.4 2.4 0 0 1 1.75-1.3 2.39 2.39 0 0 1 1.79.4c.52.37.87.92.97 1.56a2.38 2.38 0 0 1-.4 1.78 2.4 2.4 0 0 1-4.32-.98.38.38 0 0 0-.44-.3.38.38 0 0 0-.3.43 3.15 3.15 0 0 0 3.63 2.57 3.13 3.13 0 0 0 2.04-1.28 3.13 3.13 0 0 0 .53-2.35 3.13 3.13 0 0 0-1.28-2.04 3.1 3.1 0 0 0-1.73-.58zm-11.66 0a3.15 3.15 0 0 0-3 2.6 3.13 3.13 0 0 0 .53 2.36 3.13 3.13 0 0 0 2.57 1.33 3.15 3.15 0 0 0 3.1-2.62.38.38 0 1 0-.74-.13 2.4 2.4 0 0 1-2.76 1.96 2.37 2.37 0 0 1-1.56-.98 2.38 2.38 0 0 1-.4-1.78 2.4 2.4 0 0 1 4.5-.66.38.38 0 0 0 .68-.34 3.16 3.16 0 0 0-2.92-1.75z\"/></svg>";
},"useData":true});