var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 18 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M15.12 4.13a7.1 7.1 0 0 0-4.98-2.28.52.52 0 0 0-.52.48.5.5 0 0 0 .48.52 6.13 6.13 0 0 1 5.92 6.37 6.16 6.16 0 0 1-10.86 3.71 6.25 6.25 0 0 1-1.48-3.66l1.06 1.27a.5.5 0 0 0 .7.06.5.5 0 0 0 .06-.7L3.63 7.65l-.02-.02a.52.52 0 0 0-.17-.13l-.04-.01a.49.49 0 0 0-.11-.04h-.05l-.03-.01-.05.01-.13.04-.08.04-.08.08a.12.12 0 0 0-.05.05l-1.75 2.43a.5.5 0 0 0 .11.7c.09.06.19.09.29.09a.5.5 0 0 0 .41-.21l.82-1.14a7.22 7.22 0 0 0 6.91 6.62h.27c3.82 0 7-3.04 7.15-6.89a7.15 7.15 0 0 0-1.91-5.13z\"/><path fill=\"currentColor\" d=\"M7.7 12.25c.13 0 .26-.05.36-.16l2.54-2.67a.5.5 0 0 0 .14-.34V5.17c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3.71l-2.4 2.53a.5.5 0 0 0 .02.71c.09.09.22.13.34.13z\"/></svg>";
},"useData":true});