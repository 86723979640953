var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 13.72 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M9.58 6.39H5.49c-.28 0-.5.22-.5.5s.22.5.5.5h4.09c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zm-5.65.01c-.28 0-.5.22-.5.5s.22.5.5.5h.02c.28 0 .49-.22.49-.5s-.24-.5-.51-.5zm5.65 2.42H5.49c-.28 0-.5.22-.5.5s.22.5.5.5h4.09c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zm-5.65 0c-.28 0-.5.22-.5.5s.22.5.5.5h.02c.28 0 .49-.22.49-.5s-.24-.5-.51-.5zm5.65 2.44H5.49c-.28 0-.5.22-.5.5s.22.5.5.5h4.09c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zm-5.65 0c-.28 0-.5.22-.5.5s.22.5.5.5h.02c.28 0 .49-.22.49-.5s-.24-.5-.51-.5z\"/><path fill=\"currentColor\" d=\"M12.22 2.74H9.89v-.35a.5.5 0 0 0-.5-.5h-.77C8.59 1.22 7.88.75 6.86.75s-1.73.47-1.76 1.14h-.77a.5.5 0 0 0-.5.5v.34H1.5a.51.51 0 0 0-.5.51V15.5c0 .28.22.5.5.5h10.72a.5.5 0 0 0 .5-.5V3.24a.5.5 0 0 0-.5-.5zm-7.39.15h.77a.5.5 0 0 0 .5-.5l-.01-.43c.03-.05.29-.21.77-.21.48 0 .74.15.76.18v.42l-.01.04c0 .28.22.5.5.5h.78v.69H4.83v-.69zM11.72 15H2V3.74h1.83v.34c0 .28.22.5.5.5H9.4a.5.5 0 0 0 .5-.5v-.34h1.83V15z\"/></svg>";
},"useData":true});