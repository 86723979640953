var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-spot__svg--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":89}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 80 80\" fill=\"none\" class=\"m-spot__svg "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":56},"end":{"line":1,"column":96}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"svgClass") || (depth0 != null ? lookupProperty(depth0,"svgClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"svgClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":97},"end":{"line":1,"column":109}}}) : helper)))
    + "\"><circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#EDEFF1\"/><path d=\"M52.6 47.7c3-5.4 4.9-14.7.6-20.1-7.2-9.2-22.6-8.6-26.5 3-1 3.3-.6 9-.8 15.3-.3 6.4 2.3 7.5 6.2 7.7l1.4.3c-.2 6.5-.6 7.3-1 9.4 2.3 16.7 10.3 10.5 20.1-1.4 0-1.2-.8-5.4 0-14v-.2z\" fill=\"#C9CDD4\"/><path d=\"M57.4 45.5a4.7 4.7 0 0 0 0-6.5 4.4 4.4 0 0 0-6.3.3 4.7 4.7 0 0 0 0 6.5 4.4 4.4 0 0 0 6.3-.3zM29.9 45.3a4.7 4.7 0 0 0 0-6.5 4.4 4.4 0 0 0-6.4.3 4.7 4.7 0 0 0 0 6.5 4.4 4.4 0 0 0 6.4-.3z\" fill=\"#C9CDD4\"/><path d=\"M41.8 48h-7s-.3 2.6 3.6 2.5c3.9-.1 3.6-1.3 3.6-2.4l-.1-.3-.1.2z\" fill=\"#EDEFF1\"/><path d=\"M28.3 64.4l6-2 18.3-.8 2.8.5L42 75H37l-8.6-10.7z\" fill=\"#C9CDD4\"/><path d=\"M31.8 63C17 64.2 15 71.5 15 71.5s8 9.8 25.7 9.1c17.7-.7 27.4-12.1 27.4-12.1s-1.8-7-14.5-7a24.4 24.4 0 0 1-15.2 12.9c-5-3.8-6.9-11.1-6.5-11.2l-.1-.2z\" fill=\"#7C8797\"/><path d=\"M49.3 15.4s8.9.8 8.5 8.3c-.3 6.7-2.3 14-2.3 14l-2.9 4.5V28.3c-1.4-.5-4.3.3-7.5 3.4 0-3.4 1-4.1 3-6-3-.5-4.3-1-9.9 2.5 1.1-3.2 2.4-4 4.3-5-6.5 0-15.8 4.2-16.2 8.5l-.2 4.5s-8.3-5.3-4.3-15.3c3.7-9 19.3-12.2 27.5-5.5z\" fill=\"#445269\"/></svg>";
},"useData":true});