var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 18 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M16.61 13.74l-1.94-1.45a.5.5 0 0 0-.7.11l-.26.35-.61-.45a6.68 6.68 0 0 0 1.18-3.79 6.7 6.7 0 1 0-1.81 4.55l.64.48-.27.36a.5.5 0 0 0 .1.7l1.94 1.45c.21.16.47.24.73.24l.18-.01c.32-.05.61-.22.8-.48l.27-.36c.19-.26.27-.58.23-.91-.05-.31-.22-.6-.48-.79zm-9.02.46a5.7 5.7 0 1 1 0-11.39 5.7 5.7 0 0 1 0 11.39zm8.47.65l-.27.36a.27.27 0 0 1-.15.09.18.18 0 0 1-.16-.04l-1.54-1.15.53-.71 1.54 1.15c.06.05.08.11.08.14s.02.1-.03.16z\"/><path fill=\"currentColor\" d=\"M9.24 10.17H6a1.98 1.98 0 0 0-1.95 1.95c0 .28.22.5.5.5s.5-.22.5-.5c0-.4.33-.95.95-.95h3.24c.65 0 .94.55.94.95 0 .28.22.5.5.5s.5-.22.5-.5c0-.94-.74-1.95-1.94-1.95zm-1.62-.35a2.7 2.7 0 1 0 0-5.4 2.7 2.7 0 0 0 0 5.4zm0-4.39a1.7 1.7 0 1 1 0 3.4 1.7 1.7 0 0 1 0-3.4z\"/></svg>";
},"useData":true});