var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 16.74 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M12.93 10.77c-.91 0-1.71.44-2.22 1.12L6.47 9.77a2.49 2.49 0 0 0 .01-1.54l4.24-2.12a2.78 2.78 0 0 0 2.23 1.13 2.8 2.8 0 1 0-1.98-4.76 2.73 2.73 0 0 0-.82 1.96c0 .27.05.52.12.77L6.03 7.33A2.77 2.77 0 0 0 3.81 6.2a2.8 2.8 0 0 0 0 5.59 2.8 2.8 0 0 0 2.22-1.12l4.23 2.11c-.07.25-.12.51-.12.78a2.8 2.8 0 0 0 5.58 0 2.8 2.8 0 0 0-2.79-2.79zm-1.26-7.59a1.79 1.79 0 0 1 3.06 1.26c0 .99-.8 1.79-1.79 1.79a1.79 1.79 0 0 1-1.27-3.05zm-7.86 7.61a1.8 1.8 0 0 1 0-3.59 1.8 1.8 0 1 1 0 3.59zm9.12 4.56a1.79 1.79 0 1 1 0-3.58 1.79 1.79 0 0 1 0 3.58z\"/></svg>";
},"useData":true});