var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 16.42 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M8.55 6.96h2.94c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H8.55c-.28 0-.5.22-.5.5s.23.5.5.5zm0 2.81h2.94c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H8.55c-.28 0-.5.22-.5.5s.23.5.5.5z\"/><path fill=\"currentColor\" d=\"M14.92 15.01h-.54V1.67a.52.52 0 0 0-.21-.41.52.52 0 0 0-.45-.07l-7.71 2.6a.5.5 0 0 0-.34.47v3.51H2.5a.5.5 0 0 0-.5.5v6.78h-.5a.5.5 0 0 0-.5.5c0 .28.22.5.5.5L14.92 16a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.49zM13.37 2.37v12.64l-6.71.02V4.62l6.71-2.25zM3 8.77h2.67v6.27L3 15.05V8.77z\"/></svg>";
},"useData":true});