var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":101},"end":{"line":1,"column":109}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.3 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":116}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":117},"end":{"line":1,"column":126}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M15.8 13.48c-.75 0-1.37-.61-1.37-1.37V8.69c0-2.31-1.4-4.41-3.51-5.32a2.39 2.39 0 0 0-2.27-1.72c-1.07 0-1.99.71-2.27 1.72a5.77 5.77 0 0 0-3.51 5.32v3.43c0 .75-.61 1.37-1.37 1.37-.28 0-.5.22-.5.5s.22.5.5.5h4.85a2.37 2.37 0 0 0 4.62 0h4.83c.28 0 .5-.22.5-.5s-.22-.51-.5-.51zm-7.14 1.87c-.58 0-1.07-.36-1.27-.87h2.54c-.21.51-.7.87-1.27.87zm-5.23-1.87c.27-.39.44-.86.44-1.37V8.69c0-2 1.23-3.77 3.13-4.49a.5.5 0 0 0 .32-.39 1.36 1.36 0 0 1 2.68 0 .5.5 0 0 0 .32.39c1.86.7 3.12 2.5 3.12 4.49v3.43c0 .51.16.98.43 1.37H3.43z\"/></svg>";
},"useData":true});