var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 15.34 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M10.75 5.73H8.17c-.17 0-.3.13-.3.3s.13.3.3.3h2.59c.17 0 .3-.13.3-.3s-.14-.3-.31-.3zm0 2.26H8.17c-.17 0-.3.13-.3.3s.13.3.3.3h2.59c.17 0 .3-.13.3-.3s-.14-.3-.31-.3zm0 2.26H8.17c-.17 0-.3.13-.3.3s.13.3.3.3h2.59c.17 0 .3-.13.3-.3s-.14-.3-.31-.3z\"/><path fill=\"currentColor\" d=\"M14.04 15.26h-.89l.01-.02V1.67c0-.1-.05-.19-.13-.25a.28.28 0 0 0-.28-.03L5.96 3.97a.3.3 0 0 0-.19.28v2.93H2.83a.3.3 0 0 0-.3.3v1.6c0 .17.13.3.3.3s.3-.13.3-.3v-1.3h2.63v7.46l.01.02H3.91c-.17 0-.3.13-.3.3s.13.3.3.3h10.12c.17 0 .3-.13.3-.3s-.13-.3-.29-.3zm-7.68-.02V4.46l6.19-2.36v13.14l.01.02h-6.2v-.02z\"/><path fill=\"currentColor\" d=\"M4.31 13.01c0-1.05-.63-2.98-1.65-2.98-1.03 0-1.66 1.93-1.66 2.98 0 1.16.68 1.74 1.41 1.84-.02.05-.06.09-.06.15v.56c0 .17.13.3.3.3s.3-.13.3-.3V15c0-.06-.03-.1-.06-.14.74-.11 1.42-.69 1.42-1.85zm-2.71 0c0-1.02.6-2.38 1.05-2.38.45 0 1.05 1.36 1.05 2.38 0 .78-.4 1.27-1.05 1.27s-1.05-.49-1.05-1.27z\"/></svg>";
},"useData":true});