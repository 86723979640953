var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.49 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M8.85 16.26h-.19c-.92 0-1.51-.41-2.08-.82-.4-.28-.77-.55-1.2-.62a4.25 4.25 0 0 0-1.51.04c-.13.03-.24.04-.33.04s-.19-.01-.22-.16l-.09-.35c-.06-.29-.12-.48-.23-.5-1.46-.22-1.88-.53-1.97-.75L1 13.05c0-.09.06-.16.13-.18 2.25-.37 3.25-2.65 3.28-2.75v-.01c.13-.28.16-.52.07-.72-.15-.37-1.14-.63-1.2-.66-.65-.28-.71-.54-.68-.67.04-.24.35-.4.61-.4.07 0 .13.01.18.03.3.13.56.21.8.21.33 0 .46-.13.47-.15l-.04-.47c-.07-1.06-.15-2.38.19-3.13a4.07 4.07 0 0 1 3.77-2.42h.32c.64 0 2.76.18 3.77 2.42.34.75.25 2.06.19 3.12v.04c-.01.15-.01.29-.03.43.01.01.15.13.43.15.22-.01.47-.07.75-.21.09-.04.18-.04.24-.04a.8.8 0 0 1 .27.04c.22.07.37.24.37.4s-.12.38-.69.62c-.06.03-.13.04-.22.07-.33.1-.83.27-.99.62-.09.19-.06.44.07.72v.01c.04.1 1.05 2.39 3.3 2.75.09.01.15.09.13.18l-.03.09c-.09.22-.5.52-1.97.75-.12.01-.16.18-.24.5l-.09.35c-.03.1-.1.16-.21.16h-.01c-.07 0-.19-.01-.33-.04-.25-.04-.52-.09-.87-.09-.21 0-.41.01-.64.06-.44.07-.81.34-1.2.6-.54.42-1.13.83-2.05.83z\"/></svg>";
},"useData":true});