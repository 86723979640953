var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-spot__svg--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":78}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 127 82\" class=\"m-spot__svg "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":85}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"svgClass") || (depth0 != null ? lookupProperty(depth0,"svgClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"svgClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":86},"end":{"line":1,"column":98}}}) : helper)))
    + "\"><path fill=\"#F5F7F7\" d=\"M80.6 81C50.8 83 13 75.8 8.4 69.4 4.5 63.8-2.6 53 1.8 42.6c3-6.8 20.4-18 41.5-23.3a21 21 0 0 0-1.8 8.5v13.8c0 4.1-3.4 7.5-7.5 7.5h21.3A7.5 7.5 0 0 0 70 49h21.3a7.5 7.5 0 0 1-7.5-7.5V27.9c0-3.5-.8-6.7-2.3-9.6 15 3.1 39.4 11 43.2 20.8 10 26-21.7 40.5-44.2 42z\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M70.1 49a7.5 7.5 0 0 1-14.8 0\"/><path fill=\"#E3E6E9\" d=\"M91.4 49c1.8-.2-4.4-.9-5.5-2.3a6.7 6.7 0 0 1-1.6-4.4v-6c-1.1 0-2.4 0-3.7-.2-1-.2-2-.5-3.1-1 0 7-1.6 13.4-6.8 14 9.9.6 16.8.6 20.7 0zM79.1 14.4c-3-3.8-9.4-6.2-9.5-6.2 2.3 2 4 5 5.3 9 1.5-1.3 2.4-2 4.2-2.8z\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M84 36.9c-.2 4.5.2 7.4 1.1 8.8a7.2 7.2 0 0 0 6.3 3.4\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M78.5 13.8A23 23 0 0 0 70.1 8c-.6-3.6-3.6-6.4-7.4-6.4S55.9 4.3 55.3 8a21.3 21.3 0 0 0-13.8 19.9v13.7c0 4.1-3.4 7.5-7.5 7.5h57.4\"/><g><path fill=\"#0094C8\" stroke=\"#0094C8\" stroke-width=\"1.5\" d=\"M83 16a9 9 0 1 1 0 18 9 9 0 0 1 0-18z\"/><path fill=\"#FFF\" d=\"M82.8 27a1 1 0 0 1-1-1v-5.7c0-.6.4-1 1-1s1 .4 1 1V26c0 .6-.4 1-1 1zM82.8 31.2c-.6 0-1.1-.5-1.1-1.2s.5-1.1 1-1.1 1.2.5 1.2 1.1c0 .7-.5 1.2-1.1 1.2z\"/></g></svg>";
},"useData":true});