var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M13.8 7a8.6 8.6 0 0 0-2.14 16.97.37.37 0 0 0 .45-.28.37.37 0 0 0-.28-.45 7.85 7.85 0 0 1 3.53-15.29 7.86 7.86 0 0 1 5.88 9.41.38.38 0 0 0 .73.17 8.6 8.6 0 0 0-6.44-10.3A8.59 8.59 0 0 0 13.8 7zm-2.42 4.24a.38.38 0 0 0-.38.38v7.82a.38.38 0 0 0 .75 0v-1.97h3.15a3.12 3.12 0 0 0 3.12-3.11 3.12 3.12 0 0 0-3.12-3.12zm.37.75h3.15a2.37 2.37 0 0 1 2.37 2.37 2.37 2.37 0 0 1-2.37 2.36h-3.15zm7 6.75c-.61 0-1.2.39-1.51.99l-.33.67-.14.28-.36.71-.09-.13a.38.38 0 0 0-.31-.17h-1.96a.77.77 0 0 0-.77.77v.78c0 .42.37.75.68.76l1.05.26-.1.14c-.3.45-.45.97-.45 1.5v2.82c0 .4.15.75.4 1.02v1.42c0 .42.33.77.76.77h2.74a.77.77 0 0 0 .76-.77v-.39a.37.37 0 1 0-.75 0l-.01.4H15.6v-.96c.13.04.27.06.41.06h13.3c.15 0 .28-.02.4-.06v.97l-2.76-.02v-.39a.38.38 0 0 0-.75 0v.39a.77.77 0 0 0 .77.77h2.74a.77.77 0 0 0 .77-.77v-1.42c.24-.27.39-.63.39-1.02v-2.81c0-.54-.16-1.06-.46-1.51l-.1-.14.96-.25a.77.77 0 0 0 .77-.77v-.78a.77.77 0 0 0-.77-.77h-1.95a.38.38 0 0 0-.32.17l-.08.13-.83-1.66c-.31-.6-.9-.99-1.51-.99zm0 .75h7.83c.37 0 .7.3.84.58l.18.37.15.3.42.83.15.3.12.24-.15.22-1 .33h-9.26l-1-.33-.14-.22.12-.24.15-.3.75-1.5c.14-.29.46-.58.84-.58zm-4.7 2.35h1.76l.67 1.01c.05.07.11.12.2.15l1.17.39.12.02h9.39a.36.36 0 0 0 .12-.02l1.17-.4a.38.38 0 0 0 .2-.14l.67-1h1.77v.8l-1.67.41a.38.38 0 0 0-.23.57l.4.59c.21.32.32.7.32 1.09v2.81a.8.8 0 0 1-.8.8h-13.3a.8.8 0 0 1-.8-.8v-2.81c0-.4.12-.77.33-1.1l.39-.58a.38.38 0 0 0-.22-.57l-1.57-.4-.1-.02zm1.96 2.77a.38.38 0 0 0-.27.64l.79.79c.07.07.16.1.26.1h1.96a.38.38 0 0 0 0-.74h-1.8l-.68-.68a.37.37 0 0 0-.26-.1zm13.3 0a.38.38 0 0 0-.26.11l-.67.68h-1.8a.38.38 0 0 0 0 .75h1.95c.1 0 .2-.04.27-.11l.78-.79a.37.37 0 0 0 0-.53.37.37 0 0 0-.26-.1zm-10.56 2.74a.38.38 0 0 0 0 .75h7.83a.38.38 0 0 0 0-.75z\"/></svg>";
},"useData":true});