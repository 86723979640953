var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M9.8 10c-1 0-1.8.8-1.8 1.8v11.22l-2.84 1.99a.37.37 0 0 0-.13.44l-.02.06a.63.63 0 0 0 .1.45c.85 1.37 1.97 1.49 2.96 1.49h20.89c1.63 0 2.5-.22 3.3-1.5a.63.63 0 0 0 .07-.5.37.37 0 0 0-.13-.44l-3.06-2.14V11.8a1.8 1.8 0 0 0-1.8-1.8zm0 .75h17.55c.5 0 .9.37 1 .84H8.8c.1-.47.5-.84 1-.84zm-1.05 1.6h19.64V22.8H8.75zm5.17.78a.37.37 0 0 0-.26.11l-3.5 3.5a.38.38 0 0 0 .53.54l3.5-3.5a.38.38 0 0 0-.27-.65zm-2.5.19a.37.37 0 0 0-.27.1L9.8 14.79a.38.38 0 1 0 .53.53l1.35-1.35a.38.38 0 0 0-.26-.64zM28.8 23.54l2 1.4h-9.9a.88.88 0 0 0-.41.1.7.7 0 0 0-.18.13c-.05.06-.21.22-1.6.22-1.1 0-1.28-.12-1.3-.15l-.14-.14a.9.9 0 0 0-.52-.16H6.56l1.99-1.39H28.8zm-12 2.15c.23.3.59.45 1.92.45 1.56 0 1.9-.2 2.18-.45h10.64c-.59.86-1.17 1-2.58 1H8.06c-1 0-1.66-.16-2.24-1z\"/></svg>";
},"useData":true});