var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-spot__svg--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":78}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 142 91\" class=\"m-spot__svg "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":85}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"svgClass") || (depth0 != null ? lookupProperty(depth0,"svgClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"svgClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":86},"end":{"line":1,"column":98}}}) : helper)))
    + "\"><path fill=\"#F5F7F7\" d=\"M1.8 46.8c4.9-11.6 46.5-34.1 83.6-28.3 15.3 2.4 49.4 12 54.1 24.3 11.2 29.1-24.2 45.4-49.4 47-33.5 2.3-76-6-81-13-4.3-6.3-12.3-18.4-7.3-30\"/><path fill=\"#FFF\" d=\"M48.7 38.7V1.5c0-.3.3-.6.6-.6H93c.4 0 .7.3.7.6v36.2l5 1L96 67H45.5l-1.6-3.8V37.7l4.8 1z\"/><g fill=\"#A2AAB6\"><path d=\"M48.7 32.9l-4.9 3.6 4.9 2.6v-6.2zM93.2 31.5l5.7 5-6 2.8.3-7.8z\"/></g><path fill=\"#E3E6E9\" d=\"M98.4 66.7V37.2c0-.2-.2-.4-.4-.3l-2.6 1s1.3 27.3-10.9 28.8h14z\"/><g fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M98.6 60v3.3c0 2.3-1.7 4.1-3.8 4.1H47.6c-2.1 0-3.8-1.8-3.8-4.1V36.9l4.9-4.1M98.6 53.1V55M92.8 31.4l5.8 5V49\"/></g><path fill=\"#E3E6E9\" d=\"M90 41.3L90.2.9h4.3c.8 0 1 .3 1 .8V38L90 41.3z\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M48.7 38.7V1.5c0-.3.3-.6.6-.6H93c.4 0 .7.3.7.6v37.2\"/><path fill=\"none\" stroke=\"#377800\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M67.7 19.8l.3.3\"/><path fill=\"#8BC72A\" d=\"M72.4 21.6s5.7-4.3 5.7-8.4-5-4.4-5.7 0c-.5-4.4-5.9-4-5.9.3 0 4.5 6 8.1 6 8.1\"/><path fill=\"#619F00\" d=\"M75.9 13.1s1 1.4-1.3 4.3c-1.2 1.5-3 3-3 3l-1.4-1.1s1.2-.2 3.8-2.9c2.1-2.2 1.9-3.3 1.9-3.3\"/><path fill=\"none\" stroke=\"#377800\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M69.4 21.2l1.7 1.2s6.6-4.7 6.6-9.2-6-4.9-6.6 0c-.5-4.9-6.6-4.5-6.6.4 0 1.7.8 3.4 2 4.8\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M60 28.4h-3M87 28.4H63.2M69 34.8H57M87 34.8H72M98.4 36.5l-26.8 13L50 39.3l-6-2.8\"/></svg>";
},"useData":true});