var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M24.36 8a.38.38 0 0 0-.38.37c0 .02-.02 1.45-1.42 1.98-.41.16-.78.27-1.11.37-.97.28-1.74.51-2.4 1.59a3.32 3.32 0 0 0-.25 2.91 4.29 4.29 0 0 1-2.24-.76 4.83 4.83 0 0 0-1.56-.68c-1.2-.31-2.34-.1-3.41.63-1.45.98-2.45 2.72-3.04 4.01a17.02 17.02 0 0 0-1.3 4.2c-.36 2.43-.52 5.64.78 7.18.97 1.15 2.5 1.36 4.56-.21.82-.62 1.7-1.47 2.54-2.48.2-.24.5-.38.81-.38h6.3c.31 0 .61.14.82.38.84 1 1.72 1.86 2.54 2.48.98.74 1.85 1.12 2.58 1.12.8 0 1.47-.3 1.98-.91 1.04-1.24 1.3-3.65.77-7.18a17.1 17.1 0 0 0-1.3-4.2c-.58-1.29-1.58-3.03-3.03-4a4.08 4.08 0 0 0-3.42-.64 4.83 4.83 0 0 0-1.55.68 4.33 4.33 0 0 1-2.05.75.38.38 0 0 0-.04-.16l-.03-.07a2.6 2.6 0 0 1 .18-2.28c.5-.83 1.06-1 1.97-1.26.35-.1.74-.22 1.17-.39a2.98 2.98 0 0 0 1.9-2.67.38.38 0 0 0-.37-.38zm-10.27 6.4a4.11 4.11 0 0 1 2.04.68 5.04 5.04 0 0 0 2.86.9h.2a5.03 5.03 0 0 0 2.86-.9 4.14 4.14 0 0 1 1.32-.58 3.34 3.34 0 0 1 2.81.53c1.3.88 2.23 2.5 2.77 3.7.7 1.55 1.1 3.08 1.24 4 .48 3.22.27 5.55-.6 6.58-.37.44-.83.65-1.41.65-.56 0-1.3-.33-2.13-.97a15.7 15.7 0 0 1-2.42-2.36 1.81 1.81 0 0 0-1.39-.65h-6.3a1.82 1.82 0 0 0-1.38.65c-.81.96-1.65 1.78-2.42 2.36-.84.64-1.57.97-2.13.97-.58 0-1.04-.21-1.4-.65-.88-1.03-1.1-3.37-.61-6.58.14-.92.54-2.45 1.24-4 .54-1.2 1.47-2.82 2.77-3.7a3.44 3.44 0 0 1 2.08-.63zm10.27 3.54a2.48 2.48 0 0 0-2.48 2.49 2.48 2.48 0 0 0 2.48 2.48 2.48 2.48 0 0 0 2.48-2.48 2.48 2.48 0 0 0-2.48-2.49zm-10.48.12a.38.38 0 0 0-.37.37v1.62h-1.62a.38.38 0 0 0 0 .75h1.62v1.62a.38.38 0 0 0 .75 0V20.8h1.62a.38.38 0 0 0 0-.75h-1.62v-1.62a.38.38 0 0 0-.38-.37zm10.48.63a1.74 1.74 0 0 1 0 3.47 1.74 1.74 0 0 1-1.73-1.73c0-.96.77-1.74 1.73-1.74z\"/></svg>";
},"useData":true});