var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.22 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M14.99 6.89c-.57-.08-1.07-.12-1.5-.16-1.04-.09-1.54-.15-1.72-.43-.26-.41-.12-.8.17-1.45.61-1.4.31-2.33-.04-2.86-.45-.67-1.13-.95-1.98-.82a.5.5 0 0 0-.42.4 9.9 9.9 0 0 1-1.62 3.91 7.24 7.24 0 0 1-2.19 2.18.5.5 0 0 0-.21.67.5.5 0 0 0 .67.21A8 8 0 0 0 8.7 6.06c.8-1.12 1.37-2.44 1.71-3.92.28.02.5.15.66.4.32.47.3 1.11-.07 1.96-.27.61-.64 1.45-.07 2.33.45.7 1.26.78 2.47.89.42.04.9.08 1.45.16.29.04.38.65.35 1.21-.09 2.48-1.73 5.67-2.31 5.71-.4 0-1.09-.13-1.96-.29a52.72 52.72 0 0 0-5.08-.74c-.24-.02-.71-.02-1.08-.01V7.87a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0-.5.5v6.75c0 .28.22.5.5.5h2.79c.23 0 .42-.16.47-.37.37-.01.82-.01 1.02.01 2.11.2 3.77.5 4.99.73.96.18 1.65.31 2.14.31 1.63 0 3.16-4.32 3.3-6.48.13-1.96-.71-2.37-1.22-2.43zM2 8.37h1.79v5.75H2V8.37z\"/></svg>";
},"useData":true});