var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":101},"end":{"line":1,"column":109}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 13.4 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":116}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":117},"end":{"line":1,"column":126}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M11.24 1.83H2.16C1.52 1.83 1 2.35 1 2.99v11.78c0 .64.52 1.16 1.16 1.16h9.08c.64 0 1.16-.52 1.16-1.16V2.99c0-.64-.52-1.16-1.16-1.16zm-9.08 1h9.08c.09 0 .16.07.16.16v1.04H2V2.99c0-.09.07-.16.16-.16zm9.21 2.21v7.52H2.04V5.04h9.33zm-.13 9.89H2.16a.16.16 0 0 1-.16-.16v-1.22h9.4v1.22c0 .09-.07.16-.16.16z\"/><path fill=\"currentColor\" d=\"M6.7 13.78c-.25 0-.46.2-.46.46s.2.46.46.46.46-.2.46-.46-.21-.46-.46-.46zM2.8 7.24c.13 0 .26-.05.35-.15l.91-.91c.2-.2.2-.51 0-.71s-.51-.2-.71 0l-.91.92a.5.5 0 0 0 0 .71c.1.09.23.14.36.14zm.96.21a.48.48 0 0 0 .7 0l1.19-1.19c.2-.2.2-.51 0-.71s-.51-.2-.71 0l-1.18 1.2c-.2.19-.2.51 0 .7zm-.85.21l-.32.33a.5.5 0 0 0 0 .71c.1.1.23.15.35.15s.26-.06.36-.16l.33-.33c.2-.2.2-.51 0-.71s-.52-.18-.72.01zm3.33-3.73h.92c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-.92c-.28 0-.5.22-.5.5s.23.5.5.5z\"/></svg>";
},"useData":true});