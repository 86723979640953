var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M24.9 9a.36.36 0 0 0-.16.01l-3.72.97a.38.38 0 0 0-.27.46.37.37 0 0 0 .46.27l2.68-.7c-1.44 2-3.46 4.08-5.14 5.25a17.14 17.14 0 0 1-7 2.96.38.38 0 0 0 .14.74 17.88 17.88 0 0 0 7.29-3.09 24.66 24.66 0 0 0 5.28-5.36v2.75a.38.38 0 0 0 .75 0V9.38a.38.38 0 0 0-.3-.37zm-.08 8.23a.38.38 0 0 0-.37.37v9.2c0 .06.02.12.05.17h-5.34v-6.2a.38.38 0 0 0-.75 0v6.2H13.1l.02-.09V22.9a.38.38 0 0 0-.75 0v3.98c0 .03 0 .06.02.1H9.38a.38.38 0 0 0 0 .74H28.2a.38.38 0 0 0 0-.75h-3.05a.36.36 0 0 0 .05-.18V17.6a.38.38 0 0 0-.38-.37z\"/></svg>";
},"useData":true});