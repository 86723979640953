var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 24 24\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path d=\"M11.99 2a10 10 0 0 0-3.63 19.31c-.1-.8-.18-2 .03-2.87.2-.78 1.18-4.97 1.18-4.97s-.32-.6-.32-1.49c0-1.39.8-2.44 1.8-2.44.86 0 1.26.65 1.26 1.41 0 .86-.55 2.14-.81 3.34-.23.99.49 1.8 1.48 1.8 1.77 0 3.14-1.86 3.14-4.57 0-2.4-1.71-4.07-4.17-4.07a4.33 4.33 0 0 0-4.5 4.34c0 .86.32 1.8.73 2.29.08.1.1.19.08.28l-.28 1.14c-.04.2-.16.23-.35.14-1.23-.6-2.01-2.42-2.01-3.89 0-3.16 2.28-6.05 6.6-6.05 3.46 0 6.16 2.47 6.16 5.77 0 3.44-2.16 6.23-5.17 6.23-1.01 0-1.96-.54-2.29-1.15l-.62 2.38c-.23.88-.84 1.97-1.24 2.63A10 10 0 1 0 11.99 2\" fill=\"currentColor\" fill-rule=\"nonzero\"/></svg>";
},"useData":true});