var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":101},"end":{"line":1,"column":109}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.7 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":116}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":117},"end":{"line":1,"column":126}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M9 1.2a7.64 7.64 0 0 0-6.6 11.6l-1.3 4 4.4-1.2c1.1.6 2.3.9 3.6.9A7.64 7.64 0 1 0 9 1.2zm4.8 11.1l-.5.5c-1.5 1.5-3.2.8-4.7-.7l-1.4-1.4-1.4-1.4c-1.5-1.5-2.2-3.2-.7-4.7l.5-.5c.2-.2.5-.3.8-.1.7.4 1.4 1.7 1.4 1.7.2.2.2.6-.1.7 0 0-.5.4-.6.5-.3.5-.3 1 .1 1.4l1.1 1.1 1.1 1.1c.5.5.9.5 1.4.1.1-.1.5-.6.5-.6.2-.3.5-.3.8-.1 0 0 1.3.8 1.7 1.4.2.4.2.8 0 1z\"/></svg>";
},"useData":true});