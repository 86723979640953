var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.03 7a3.23 3.23 0 0 0-3.23 3.23c0 1.16.62 2.16 1.53 2.73a.36.36 0 0 0-.02.19l1.35 7.88a.37.37 0 0 0 .17.25c.1.06.2.07.3.04l6.12-1.7 5.03 5.65a.38.38 0 0 0 .5.05l1.94-1.43a.38.38 0 0 0-.44-.6L27.6 24.5l-4.95-5.56a.37.37 0 0 0-.38-.1l-5.95 1.64-.75-4.38h4.21a.38.38 0 0 0 0-.75h-4.34l-.35-2.05a3.23 3.23 0 0 0 4.16-3.08A3.23 3.23 0 0 0 16.03 7zm0 .75a2.48 2.48 0 0 1 2.48 2.48 2.48 2.48 0 0 1-2.48 2.48 2.48 2.48 0 0 1 0-4.96zm-3.46 7.26a.36.36 0 0 0-.14.04A8 8 0 0 0 8 22.23c0 4.43 3.6 8.04 8.03 8.04a8.02 8.02 0 0 0 7.8-6.09.38.38 0 0 0-.28-.45.38.38 0 0 0-.45.27 7.27 7.27 0 0 1-7.07 5.52 7.29 7.29 0 0 1-7.28-7.29 7.25 7.25 0 0 1 4.02-6.5.37.37 0 0 0-.2-.72z\"/></svg>";
},"useData":true});