var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M10.7 10a.38.38 0 0 0-.37.38v12.21c0 .21.17.38.38.38h20.36a.38.38 0 0 0 .38-.38V10.38a.38.38 0 0 0-.38-.38zm.38.75h2.76a3.04 3.04 0 0 1-2.76 2.7zm3.5 0h12.6a3.79 3.79 0 0 0 3.52 3.44v4.57a3.78 3.78 0 0 0-3.53 3.46H14.6a3.78 3.78 0 0 0-3.5-3.45v-4.58a3.78 3.78 0 0 0 3.5-3.44zm13.35 0h2.77v2.7a3.04 3.04 0 0 1-2.77-2.7zm-6.78 2.21a3.61 3.61 0 0 0-3.4 2.36h-.82a.38.38 0 0 0 0 .75h.64c-.02.14-.05.28-.05.42 0 .14.03.28.05.42h-.64a.38.38 0 0 0 0 .75h.81a3.6 3.6 0 0 0 3.4 2.37c.62 0 1.6-.27 2.26-.8a.37.37 0 0 0 .06-.52.38.38 0 0 0-.52-.06c-.5.4-1.31.63-1.8.63a2.86 2.86 0 0 1-2.6-1.62H21a.38.38 0 0 0 0-.75h-2.67a2.7 2.7 0 0 1-.05-.42c0-.14.03-.28.05-.42h2.67a.38.38 0 0 0 0-.75h-2.44a2.88 2.88 0 0 1 2.6-1.6c.68 0 1.3.26 1.65.52a.38.38 0 0 0 .44-.61 3.74 3.74 0 0 0-2.1-.67zM7.38 14.28a.38.38 0 0 0-.38.38v11.27c0 .2.17.38.38.38h19.37a.38.38 0 0 0 .38-.38v-2.31a.38.38 0 0 0-.75 0v1.93H7.75V15.04h1.87a.38.38 0 0 0 0-.74zm3.7 5.23a3.04 3.04 0 0 1 2.76 2.7h-2.76zm19.62 0v2.7h-2.78a3.04 3.04 0 0 1 2.78-2.7z\"/></svg>";
},"useData":true});