var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-spot__svg--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":71},"end":{"line":1,"column":79}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 150 100\" class=\"m-spot__svg "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":86}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"svgClass") || (depth0 != null ? lookupProperty(depth0,"svgClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"svgClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":87},"end":{"line":1,"column":99}}}) : helper)))
    + "\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#F5F7F7\" d=\"M3 54.7c5-12 48.4-35.6 87-29.5 16 2.5 51.6 12.5 56.5 25.4 11.6 30.3-25.3 47.3-51.5 49C60 102 15.9 93.3 10.6 86 6.1 79.5-2.2 67 3 54.7z\"/><path fill=\"#FFF\" d=\"M50 30.2h15.8v39.7H50z\"/><path fill=\"#E3E6E9\" d=\"M50.6 36.9h1s.3-2.4 2.4-3.7c2-1.3 4-1.6 4-1.6v-1h-6.5c-.5 0-.9.4-.9 1v5.3z\"/><path fill=\"#FFF\" d=\"M66.4 69.3V14.6L99 2v67.3\"/><path fill=\"#E3E6E9\" d=\"M99 69.9V2.3c0-.2-.2-.3-.3-.3l-2.2 1s1 65.5-9 66.9H99z\"/><path fill=\"#FFF\" d=\"M56.5 57c0 5-2.9 7.6-6.5 7.6-3.7 0-6.6-2.6-6.6-7.6s3-13 6.6-13c3.6 0 6.5 8 6.5 13z\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" d=\"M88.9 23.2H76.3M88.9 34.1H76.3M88.9 45.1H76.3M66.2 30.2H50.5M50.5 30.2v9.2\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" d=\"M66.2 69.3V14.6L99 2v67.3M54.3 69.4h50.5\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" d=\"M49.6 69.4v-4.8M56.2 57c0 5-3 7.6-6.6 7.6S43.1 62 43.1 57s2.9-13 6.5-13 6.6 8 6.6 13z\"/></svg>";
},"useData":true});