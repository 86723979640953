var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M13.22 9c-.1 0-.2.04-.27.11a.38.38 0 0 0-.1.27V10.28H9.44a.38.38 0 0 0-.37.31c-.02.1-.41 2.27.82 3.76.73.88 1.85 1.32 3.33 1.32h-.03c.08.24.17.48.29.74.8 1.8 2.6 3.71 5.3 3.87v4.62h-2.26a.38.38 0 0 0 0 .75h5.34a.38.38 0 0 0 0-.75h-2.33v-4.62a6.24 6.24 0 0 0 5.58-4.58h.05c1.48 0 2.6-.44 3.32-1.31 1.24-1.5.84-3.68.83-3.77a.37.37 0 0 0-.37-.3h-3.51v-.95a.38.38 0 0 0-.38-.37zm.38.75h11.08v4.04c0 .52-.14 1.46-.53 2.37a5.47 5.47 0 0 1-5 3.39 5.54 5.54 0 0 1-4.99-3.44 5.98 5.98 0 0 1-.55-2.33v-.23l-.01-3.8zm-3.83 1.28h3.08V13.48l.01.38a5.8 5.8 0 0 0 .13 1.07c-1.13-.04-1.97-.39-2.51-1.04-.77-.93-.76-2.25-.7-2.85zm15.66.04H28.6c.05.6.06 1.92-.7 2.84-.56.67-1.44 1.01-2.6 1.04h-.15.14a6.24 6.24 0 0 0 .13-1.16zm-10.9 15.82a.38.38 0 0 0 0 .75h9.28a.38.38 0 0 0 0-.75z\"/></svg>";
},"useData":true});