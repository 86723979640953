var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.44 6a3.82 3.82 0 0 0-3.81 3.83v1.68H8.17a.38.38 0 0 0-.37.36L7 28.31a.37.37 0 0 0 .38.4h8.85a.38.38 0 0 0 0-.75H7.77l.76-15.71h15.82l.15 3.02c.01.2.16.38.4.35a.38.38 0 0 0 .35-.39l-.16-3.38a.38.38 0 0 0-.38-.35h-4.46V9.83A3.82 3.82 0 0 0 16.44 6zm0 .75a3.07 3.07 0 0 1 3.06 3.08v1.68h-6.12V9.83a3.07 3.07 0 0 1 3.06-3.08zm4.47 9.43a2.4 2.4 0 0 0-2.38 2.39c0 .63.25 1.21.66 1.64h-2.1a.38.38 0 0 0-.38.37v2.84c0 .2.17.37.38.37h.52v6.67c0 .21.16.38.37.38h11.54a.38.38 0 0 0 .37-.38V23.8h.52a.38.38 0 0 0 .37-.37v-2.84a.38.38 0 0 0-.37-.37h-2.1c.4-.43.66-1 .66-1.64a2.39 2.39 0 0 0-2.39-2.4c-1.26 0-2.27 1-2.83 2.73-.57-1.73-1.58-2.72-2.84-2.72zm0 .75c1.12 0 2 1.25 2.39 3.28H20.9a1.64 1.64 0 0 1 0-3.28zm5.67 0a1.64 1.64 0 0 1 0 3.28H24.2c.38-2.03 1.27-3.28 2.38-3.28zm-9.11 4.03h4.56v2.08h-4.56zm5.31 0h1.93v9.13h-1.93zm2.69 0h4.56v2.08h-4.56zm-7.11 2.83h3.67v6.3h-3.67zm7.1 0h3.68v6.3h-3.68z\"/></svg>";
},"useData":true});