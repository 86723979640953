var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17.04 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M10.97 9.99c-.28 0-.56-.07-.81-.22a.5.5 0 0 1-.17-.69.5.5 0 0 1 .69-.17c.44.27 1.23-.19 1.77-.73l2.13-2.13c.61-.61.67-1.56.12-2.11l-1.11-1.11c-.55-.55-1.49-.49-2.1.12L9.34 5.08c-.78.79-.93 1.23-.64 1.88a.5.5 0 0 1-.25.66.5.5 0 0 1-.66-.25c-.59-1.3.06-2.21.85-3l2.13-2.13c1-1 2.58-1.06 3.52-.12l1.11 1.11c.94.94.88 2.52-.12 3.52l-2.13 2.13c-.6.6-1.41 1.11-2.18 1.11zM4.4 16.54a2.3 2.3 0 0 1-1.65-.67l-1.11-1.11A2.3 2.3 0 0 1 .98 13c.03-.66.31-1.28.79-1.76L3.9 9.11c.79-.79 1.7-1.43 3-.85a.5.5 0 0 1 .25.66.5.5 0 0 1-.67.26c-.65-.3-1.09-.15-1.88.64l-2.13 2.13c-.3.3-.48.69-.5 1.1-.02.39.12.75.37 1.01l1.11 1.11c.55.55 1.49.49 2.1-.12l2.13-2.13c.54-.54.96-1.35.69-1.81a.5.5 0 0 1 .17-.69.5.5 0 0 1 .69.17c.57.95-.01 2.2-.84 3.03l-2.13 2.13c-.51.53-1.19.79-1.86.79z\"/><path fill=\"currentColor\" d=\"M5.35 12.67a.47.47 0 0 1-.35-.15.5.5 0 0 1 0-.71l6.35-6.35c.2-.2.51-.2.71 0s.2.51 0 .71L5.7 12.53a.5.5 0 0 1-.35.14z\"/></svg>";
},"useData":true});