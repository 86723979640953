var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M19.87 9c-.77-.05-1.62.3-2.12 1.16a2.18 2.18 0 0 0-1.99-1.14c-1.1.04-2.22.94-2.22 2.58 0 2.97 3.81 5.41 3.97 5.52a.37.37 0 0 0 .43-.02c.16-.12 3.95-2.94 3.95-5.7 0-1.55-1.05-2.33-2.02-2.4zm-.06.75c.66.05 1.33.58 1.33 1.65 0 2.04-2.67 4.32-3.44 4.94-.78-.55-3.41-2.55-3.41-4.74 0-1.22.77-1.8 1.5-1.83h.04c.7 0 1.43.5 1.51 1.66.01.19.17.34.36.34a.38.38 0 0 0 .38-.32c.18-1.24 1-1.74 1.73-1.7zm-7.57 9.44c-2.08 0-3.74 1-5.08 2a.38.38 0 0 0 .44.6c1.25-.91 2.78-1.86 4.64-1.86.86 0 1.54.26 2.23.54l.34.15c.36.16.56.24 1.22.28h4.22c.63 0 1.33.39 1.33.94 0 .73-.33 1.07-1.04 1.07h-4.58a.38.38 0 0 0 0 .75h4.58c.98 0 1.6-.51 1.76-1.4l.78-.3.45-.19a264.65 264.65 0 0 0 3.42-1.38c1.14-.49 1.97-.68 2.31-.05.2.34.27.77-.57 1.35-.5.34-9.24 5.62-10.07 6.08-2.87-.02-4.3-.92-5.81-1.87a42.31 42.31 0 0 0-.7-.43.39.39 0 0 0-.3-.03l-4.56 1.55a.37.37 0 0 0-.23.48c.07.2.28.3.48.23l4.38-1.5.53.33c1.55.98 3.15 2 6.3 2 .31 0 10.32-6.16 10.4-6.22 1.25-.87 1.13-1.75.81-2.33-.74-1.36-2.44-.63-3.26-.28l-4.38 1.76c-.22-.82-1.16-1.31-2.03-1.31h-4.2a1.9 1.9 0 0 1-.92-.21l-.38-.17a6.46 6.46 0 0 0-2.51-.58z\"/></svg>";
},"useData":true});