var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 16.33 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M13.17 3.85a7.24 7.24 0 0 0-5.08-2.02c-1.91.03-3.7.79-5.03 2.16a7.07 7.07 0 0 0-2.03 5.09c.03 1.91.79 3.7 2.16 5.03a7.1 7.1 0 0 0 4.99 2.03h.1a7.11 7.11 0 0 0 4.96-2.1 7.12 7.12 0 0 0 2.1-5.15 7.15 7.15 0 0 0-2.17-5.04zM8.26 15.13c-1.66.04-3.2-.6-4.38-1.74a6.1 6.1 0 0 1-1.86-4.33c-.02-1.64.6-3.2 1.74-4.38A6.17 6.17 0 0 1 8.1 2.83h.09c1.61 0 3.13.62 4.29 1.74a6.1 6.1 0 0 1 1.86 4.33 6.15 6.15 0 0 1-1.73 4.36 6.18 6.18 0 0 1-4.35 1.87z\"/><path fill=\"currentColor\" d=\"M8.39 8.76L8.35 5.8a.5.5 0 0 0-.5-.49h-.01c-.28 0-.5.23-.49.51L7.39 9c0 .14.06.27.17.37l3.51 3.13c.1.08.21.13.33.13a.5.5 0 0 0 .33-.88L8.39 8.76z\"/></svg>";
},"useData":true});