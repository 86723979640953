var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.31 8c-1.05 0-1.92.83-1.92 1.85v1.06l.02.08H7.64a.65.65 0 0 0-.64.66v14.32c0 .36.29.65.65.65h12.5a.38.38 0 0 0 .37-.37.38.38 0 0 0-.37-.38H7.75v-8.7h8.58v.87c0 .3.24.54.53.54h2.55c.3 0 .54-.24.54-.54v-.87h8.57v1.77a.38.38 0 0 0 .75 0v-7.3a.65.65 0 0 0-.65-.65h-6.77l.02-.08V9.86C21.87 8.83 21 8 19.95 8zm0 .75h3.64c.64 0 1.17.5 1.17 1.1v1.06l.02.08h-6.01l.01-.08V9.86c0-.61.53-1.11 1.17-1.11zm-8.56 3h20.77v4.67h-8.57v-1.04a.54.54 0 0 0-.54-.53h-2.55a.54.54 0 0 0-.53.53v1.04H7.75zm9.33 3.85h2.12v2.23h-2.12zm8.96 3.3a5.64 5.64 0 0 0-5.44 5.8 5.78 5.78 0 0 0 1.74 4.1 5.38 5.38 0 0 0 3.77 1.56h.08a5.64 5.64 0 0 0 5.44-5.8 5.79 5.79 0 0 0-1.66-4.03 5.32 5.32 0 0 0-3.93-1.63zm.01.75h.07c1.24 0 2.42.5 3.31 1.4a5.04 5.04 0 0 1 1.45 3.51c.03 2.74-2.07 5-4.7 5.04a4.7 4.7 0 0 1-3.31-1.33 5.03 5.03 0 0 1-1.52-3.57 4.89 4.89 0 0 1 4.7-5.05zm.22 1.3a.38.38 0 0 0-.37.38l.04 3.1-2.15.02a.38.38 0 0 0 .01.75l2.53-.03a.38.38 0 0 0 .37-.38l-.05-3.47a.38.38 0 0 0-.38-.37z\"/></svg>";
},"useData":true});