var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 24 24\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path d=\"M8.26 6.66a1.6 1.6 0 0 0-1.61-1.65 1.64 1.64 0 0 0-1.66 1.64c-.01.9.75 1.65 1.64 1.65.9 0 1.63-.73 1.63-1.64m-.22 7.55V9.85c0-.23 0-.24-.24-.24H5.45c-.18 0-.23.06-.23.23v8.73c0 .18.06.23.23.23H7.8c.24 0 .24 0 .24-.25v-4.34m1.85-.02v4.38c0 .17.04.23.22.23h2.35c.25 0 .25 0 .25-.26v-4.21c0-.34.02-.67.07-1 .1-.77.5-1.26 1.16-1.42.22-.06.44-.07.67-.05.56.05.97.3 1.17.86.12.34.17.7.18 1.05v4.8c0 .17.04.23.21.23h2.39c.16 0 .22-.05.22-.22 0-1.12-.02-2.24.02-3.36.02-.7 0-1.38-.02-2.08a7.5 7.5 0 0 0-.17-1.36 2.85 2.85 0 0 0-1.03-1.8 3.72 3.72 0 0 0-2.93-.54c-.81.13-1.43.6-1.9 1.28-.02.04-.05.11-.11.1-.06-.02-.04-.1-.04-.15v-.81c0-.25 0-.25-.26-.25h-2.19c-.26 0-.26 0-.26.26v4.32M20.19 2.3c.89 0 1.5.61 1.5 1.5v16.4c0 .89-.61 1.5-1.5 1.5H3.81c-.9 0-1.52-.61-1.52-1.5V3.8c0-.89.62-1.5 1.52-1.5h16.36\" fill=\"currentColor\" fill-rule=\"nonzero\"/></svg>";
},"useData":true});