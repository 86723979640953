var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M19.55 8a10.13 10.13 0 0 0 0 20.24 10.13 10.13 0 0 0 0-20.24zm0 .75a9.38 9.38 0 0 1 0 18.74 9.38 9.38 0 0 1 0-18.74zm12.78.3c-.35 0-.64.16-.77.42a9.9 9.9 0 0 0-1.44 4.85c0 1.81.39 3.59.76 4.52l.14.35c.1.24.2.47.2.66-.04.7-.1 1.84-.18 3l-.03.6a179.1 179.1 0 0 1-.21 3.1l-.02.24-.01.09c-.03.34.08.68.31.92a1.2 1.2 0 0 0 2.08-.81V9.87a.87.87 0 0 0-.32-.66c-.18-.14-.38-.2-.5-.17zm.02.73c.02.02.06.07.06.1v17.1c0 .39-.53.58-.78.31a.46.46 0 0 1-.12-.34c.08-.74.37-5.33.45-7.08 0-.36-.13-.68-.25-.96-.04-.1-.09-.2-.13-.33-.34-.85-.7-2.55-.7-4.26a9.3 9.3 0 0 1 1.34-4.48c0-.03.05-.05.13-.05zm-12.8.64a7.7 7.7 0 0 0 0 15.4 7.7 7.7 0 0 0 0-15.4zm-14.77 0a.37.37 0 0 0-.4.35L4 15.19c-.05.6.04 1.76 1.31 2.64a.92.92 0 0 1 0 .23l-.3 9.08a.96.96 0 0 0 .27.7c.2.21.47.32.79.32h.7c.32 0 .6-.1.8-.31a.96.96 0 0 0 .26-.7L7.54 18v-.07c-.01-.03-.01-.07-.04-.07 1.3-.93 1.4-2.08 1.35-2.68l-.37-4.42a.37.37 0 0 0-.4-.35.37.37 0 0 0-.35.4l.37 4.44c.05.54-.08 1.33-1.05 2-.3.24-.27.6-.26.83l.3 9.08a.2.2 0 0 1-.05.16c-.06.05-.15.08-.26.08h-.7c-.11 0-.2-.03-.26-.08a.2.2 0 0 1-.05-.16l.3-9.12c.02-.18.03-.55-.28-.8-.94-.66-1.08-1.45-1.03-2l.37-4.42a.38.38 0 0 0-.35-.4zm1.65 0a.38.38 0 0 0-.37.38v3.99a.38.38 0 0 0 .75 0V10.8a.38.38 0 0 0-.38-.38zm13.12.75a6.96 6.96 0 0 1 6.94 6.95 6.96 6.96 0 0 1-6.94 6.95 6.96 6.96 0 0 1-6.95-6.95 6.96 6.96 0 0 1 6.95-6.95zm.12 4.01a3.01 3.01 0 0 0-2.82 1.92h-.6a.38.38 0 0 0 0 .75h.43c-.01.09-.03.17-.03.26 0 .1.02.19.03.28h-.44a.38.38 0 0 0 0 .75h.61a3 3 0 0 0 2.8 1.92c.53 0 1.35-.23 1.9-.66a.38.38 0 0 0-.47-.59c-.4.31-1.04.5-1.43.5a2.25 2.25 0 0 1-1.98-1.17h1.87a.37.37 0 1 0 0-.75h-2.11c-.01-.1-.03-.18-.03-.28 0-.09.02-.17.03-.26h2.11a.37.37 0 1 0 0-.75h-1.87a2.27 2.27 0 0 1 2-1.17c.53 0 1.02.21 1.3.41a.38.38 0 0 0 .44-.6 3.12 3.12 0 0 0-1.74-.56z\"/></svg>";
},"useData":true});