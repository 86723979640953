var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":119}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 24 24\" fill=\"none\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":126}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":127},"end":{"line":1,"column":136}}}) : helper)))
    + "\"><path d=\"M8.79 4.3c-.82.04-1.39.17-1.88.37-.5.2-.94.46-1.37.9-.43.43-.7.86-.9 1.37a5.7 5.7 0 0 0-.35 1.88c-.04.83-.04 1.09-.04 3.2 0 2.1.01 2.36.05 3.19.04.82.17 1.39.37 1.88.2.5.46.94.9 1.37.43.43.86.7 1.37.9.5.18 1.06.31 1.88.35.83.03 1.1.04 3.2.04s2.36-.01 3.2-.05a5.67 5.67 0 0 0 1.87-.37c.5-.2.94-.46 1.37-.9.43-.43.7-.86.9-1.37.18-.5.31-1.05.35-1.88.03-.83.04-1.1.04-3.2s-.01-2.36-.05-3.19a5.66 5.66 0 0 0-.37-1.88 3.8 3.8 0 0 0-.9-1.37c-.42-.43-.86-.7-1.37-.9-.5-.18-1.05-.31-1.88-.35a55.3 55.3 0 0 0-3.2-.04c-2.1 0-2.36.01-3.19.05zm.1 14.01a4.3 4.3 0 0 1-1.45-.26c-.36-.14-.62-.3-.9-.58a2.39 2.39 0 0 1-.57-.89 4.3 4.3 0 0 1-.27-1.44c-.04-.81-.05-1.06-.05-3.13s0-2.31.04-3.13c.03-.75.16-1.16.26-1.44.14-.36.3-.62.58-.89.27-.27.53-.44.89-.58.27-.1.68-.23 1.44-.27.81-.04 1.06-.05 3.13-.05s2.31 0 3.13.04c.75.03 1.17.16 1.44.26.36.14.62.3.9.58.26.27.43.53.57.89.1.27.24.68.27 1.44.04.82.05 1.06.05 3.13s0 2.31-.04 3.13a4.3 4.3 0 0 1-.26 1.44c-.14.36-.3.62-.58.9a2.4 2.4 0 0 1-.89.57c-.27.11-.68.24-1.44.27-.81.04-1.06.05-3.13.05s-2.31 0-3.13-.04M15.2 7.86a.93.93 0 1 0 1.86 0 .93.93 0 0 0-1.86 0zM8.02 12a3.98 3.98 0 1 0 7.96-.02 3.98 3.98 0 0 0-7.96.02zm1.4 0a2.58 2.58 0 1 1 5.16-.02 2.58 2.58 0 0 1-5.16.01z\" fill=\"currentColor\"/></svg>";
},"useData":true});