var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 16.02 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M14.28 3.27H1.72a.85.85 0 0 0-.85.85v7.73c0 .47.38.85.85.85h12.56c.47 0 .85-.38.85-.85V4.12a.85.85 0 0 0-.85-.85zm-.15 8.42H1.87V4.27h12.25v7.42z\"/><path fill=\"currentColor\" d=\"M6.64 10.3c.08.04.16.07.25.07s.18-.02.25-.07l3.17-1.88c.15-.09.25-.25.25-.43s-.09-.34-.25-.43L7.14 5.67c-.15-.09-.35-.09-.5 0s-.25.25-.25.43v3.76c0 .18.09.35.25.44zm.75-3.32l1.69 1-1.69 1v-2zm7.11 6.65H5.15c-.17-.27-.46-.47-.81-.47s-.64.19-.81.47H1.5c-.28 0-.5.22-.5.5s.22.5.5.5h2.03c.18.27.47.46.81.46s.63-.19.8-.46h9.35c.28 0 .5-.22.5-.5s-.22-.5-.49-.5z\"/></svg>";
},"useData":true});