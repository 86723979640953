var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 15.74 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M8.37 6.53h2.59c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H8.37c-.28 0-.5.22-.5.5s.22.5.5.5zm2.58 1.26H8.37c-.28 0-.5.22-.5.5s.22.5.5.5h2.59c.28 0 .5-.22.5-.5s-.23-.5-.51-.5zm0 2.26H8.37c-.28 0-.5.22-.5.5s.22.5.5.5h2.59c.28 0 .5-.22.5-.5s-.23-.5-.51-.5z\"/><path fill=\"currentColor\" d=\"M14.24 15.06h-.68V1.67a.49.49 0 0 0-.22-.41.5.5 0 0 0-.46-.05L6.09 3.78a.5.5 0 0 0-.32.47v2.73H3.03a.5.5 0 0 0-.5.5v1.6c0 .28.22.5.5.5s.5-.22.5-.5v-1.1h2.23v7.08H4.11c-.28 0-.5.22-.5.5s.22.5.5.5h10.12c.28 0 .5-.22.5-.5s-.22-.5-.49-.5zM6.77 4.59l5.79-2.2v12.67H6.77V4.59z\"/><path fill=\"currentColor\" d=\"M4.71 13.01c0-1.08-.65-3.18-1.85-3.18-1.2 0-1.85 2.1-1.85 3.18 0 1.02.54 1.77 1.35 1.99v.56c0 .28.22.5.5.5s.5-.22.5-.5V15c.81-.22 1.35-.97 1.35-1.99zm-2.71 0c0-1.01.61-2.18.85-2.18s.86 1.17.86 2.18c0 .67-.32 1.07-.85 1.07-.54 0-.86-.4-.86-1.07z\"/></svg>";
},"useData":true});