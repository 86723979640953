var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 16.88 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":127}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M8.4 3.5a4.59 4.59 0 0 0-2.52 8.41l.02 1.23c0 .27.23.49.5.49l2.01.01 2.06.01a.5.5 0 0 0 .5-.5v-1.21a4.78 4.78 0 0 0 2.02-3.86A4.6 4.6 0 0 0 8.4 3.5zm1.8 7.75a.5.5 0 0 0-.24.43v.97l-1.56-.01-1.52-.01-.01-1a.49.49 0 0 0-.24-.42 3.58 3.58 0 1 1 5.35-3.12c0 1.26-.69 2.5-1.78 3.16zm-.1 2.83H6.71c-.28 0-.5.22-.5.5s.22.5.5.5h3.39c.28 0 .5-.22.5-.5s-.23-.5-.5-.5zM8.4 2.93a.5.5 0 0 0 .5-.5v-1.4c0-.28-.22-.5-.5-.5s-.5.22-.5.5v1.4c0 .28.22.5.5.5zm5.61-.12a.5.5 0 0 0-.71 0l-.99.99a.5.5 0 0 0 0 .71.48.48 0 0 0 .7 0l.99-.99a.49.49 0 0 0 .01-.71zm-4.22 12.6H7.01c-.28 0-.5.22-.5.5s.22.5.5.5h2.78a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5zM4.43 4.51a.5.5 0 0 0 0-.71l-.99-.99c-.2-.2-.51-.2-.71 0s-.2.51 0 .71l.99.99c.1.1.23.15.35.15s.27-.05.36-.15zM2.9 7.75H1.5c-.28 0-.5.22-.5.5s.22.5.5.5h1.4c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zm12.48 0h-1.4c-.28 0-.5.22-.5.5s.22.5.5.5h1.4c.28 0 .5-.22.5-.5s-.22-.5-.5-.5z\"/></svg>";
},"useData":true});