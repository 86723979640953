var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M14.99 10a.36.36 0 0 0-.36.2c-.1.18-.02.41.16.51l3.82 2h-1.86l-2.4 2.4H6.24A2.24 2.24 0 0 0 4 17.35v8.47c0 .74.6 1.34 1.34 1.34h.68a.38.38 0 1 0 0-.76h-.68a.6.6 0 0 1-.59-.59v-5.19c.06.05.14.08.22.08h12.35v5.7h-2.91a.38.38 0 1 0 0 .76h12.84a.38.38 0 1 0 0-.76h-5.28v-5.7h6.18c.57 1.11 2.18 3.29 5.91 3.43v1.68a.6.6 0 0 1-.59.59h-1.03a.38.38 0 1 0 0 .76h1.03c.74 0 1.34-.6 1.34-1.34V18.5a3.39 3.39 0 0 0-3.38-3.39h-6.48l-2.4-2.4H20.2l-5.09-2.67a.19.19 0 0 0-.12-.04zm2.07 3.46h5.2l1.65 1.65h-8.5l1.65-1.65zm-10.82 2.4H11v4.1H4.97a.33.33 0 0 0-.22.08v-2.7c0-.81.67-1.48 1.49-1.48zm5.51 0h5.58v4.1h-5.58v-4.1zm6.33 0h3.16v10.55h-3.16V15.86zm3.91 0h5.89v4.1h-5.89v-4.1zm6.64 0h2.81a2.64 2.64 0 0 1 2.63 2.64v4.89c-4.2-.17-5.32-3.09-5.45-3.48v-4.05zM8.62 24.79c-.46 0-.89.16-1.23.43A1.98 1.98 0 0 0 7.07 28a1.96 1.96 0 0 0 3.15-.08c.16.23.37.42.62.56a1.95 1.95 0 0 0 2.44-.38c.32-.35.52-.82.52-1.33a2 2 0 0 0-.54-1.36 1.97 1.97 0 0 0-3.03.2 1.91 1.91 0 0 0-.75-.63 2.04 2.04 0 0 0-.86-.19zm21.24 0c-.53 0-1.01.21-1.36.55a1.98 1.98 0 0 0 1.37 3.41c.6 0 1.14-.27 1.5-.69a1.98 1.98 0 0 0 0-2.58 1.98 1.98 0 0 0-1.51-.69zm-21.24.75a1.23 1.23 0 0 1 0 2.46 1.2 1.2 0 0 1-.89-.39 1.21 1.21 0 0 1-.33-.84c0-.33.13-.64.35-.86.22-.23.53-.37.87-.37zm3.21 0a1.23 1.23 0 0 1 1.23 1.22c0 .3-.11.58-.3.8-.22.26-.56.43-.93.43a1.24 1.24 0 0 1-1.23-1.23c0-.33.13-.63.34-.85.23-.23.54-.37.89-.37zm18.03 0c.33 0 .63.13.85.34a1.22 1.22 0 0 1 .07 1.68c-.22.26-.56.43-.93.43a1.23 1.23 0 1 1 .01-2.45z\" clip-rule=\"evenodd\"/></svg>";
},"useData":true});