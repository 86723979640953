var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 15 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M13.18 14.3a5.95 5.95 0 0 1-3.07.84 5.93 5.93 0 0 1-4.27-1.72A6.16 6.16 0 0 1 4.4 11.2h5.44c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H4.11a6.64 6.64 0 0 1-.09-1.64h5.82c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H4.18a6.05 6.05 0 0 1 1.61-2.84 6.1 6.1 0 0 1 4.35-1.79c.91.02 2.18.22 3.04.85a.5.5 0 0 0 .7-.11.5.5 0 0 0-.11-.7 6.48 6.48 0 0 0-3.63-1.04 7.12 7.12 0 0 0-6.99 5.63H1.53c-.28 0-.5.22-.5.5s.22.5.5.5h1.49L3 9.02c0 .4.04.79.1 1.18H1.53c-.28 0-.5.22-.5.5s.22.5.5.5h1.81c.35 1.1.96 2.1 1.8 2.93a6.92 6.92 0 0 0 4.89 2.01h.11a6.82 6.82 0 0 0 3.63-1.02.5.5 0 0 0 .12-.7.51.51 0 0 0-.71-.12z\"/></svg>";
},"useData":true});