var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.73 8a6 6 0 0 0-4.21 1.71 6 6 0 0 0-1.82 4.17 6 6 0 0 0 1.1 3.48.38.38 0 0 0 .6-.44 5.24 5.24 0 0 1-.96-3.04 5.24 5.24 0 0 1 1.6-3.63 5.26 5.26 0 0 1 3.69-1.5c1.38 0 2.69.53 3.68 1.5a5.22 5.22 0 0 1 1.54 3 .38.38 0 0 0 .16.25.38.38 0 0 0 .3.06 4.32 4.32 0 0 1 5.29 4.2 4.31 4.31 0 0 1-3.84 4.29.37.37 0 0 0-.33.37v3.49H11.92v-3.49a.38.38 0 0 0-.34-.37 4.3 4.3 0 0 1-3.83-4.3 4.32 4.32 0 0 1 4.03-4.3.38.38 0 0 0 .35-.4.37.37 0 0 0-.4-.34A5.08 5.08 0 0 0 7 17.76a5.06 5.06 0 0 0 4.17 4.98v5.48c0 .5.4.9.9.9h13.31a.9.9 0 0 0 .9-.9v-5.48a5.06 5.06 0 0 0 4.17-4.98 5.07 5.07 0 0 0-5.83-5 5.97 5.97 0 0 0-1.69-3.05A6 6 0 0 0 18.73 8zm-.21 1.56a5.16 5.16 0 0 0-1 .08.37.37 0 1 0 .14.74c2.27-.42 4.42 1 4.9 3.22a.38.38 0 0 0 .44.29.38.38 0 0 0 .29-.45 4.9 4.9 0 0 0-4.77-3.88zM25.09 14a.38.38 0 0 0 0 .75 3.27 3.27 0 0 1 3.27 3.27.38.38 0 0 0 .75 0A4.02 4.02 0 0 0 25.09 14zM8.72 17.35a.38.38 0 0 0-.37.38 3.8 3.8 0 0 0 3.8 3.8.38.38 0 0 0 0-.76 3.05 3.05 0 0 1-3.05-3.04.38.38 0 0 0-.38-.38zm3.2 9.3h13.61v1.58a.15.15 0 0 1-.15.14H12.06a.15.15 0 0 1-.14-.14z\"/></svg>";
},"useData":true});