var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.79 5a3 3 0 0 0-2.36.94c-.83.95-.78 2.31-.68 3.04.1.72.35 1.29.6 1.81.01.03 1.05 2.67-.62 5.26-1.28 1.6-1.88 4.08-1.7 6.99.26 4.19 1.06 6.43 2.44 9.08.28.54.98.59 1.7.59h.96c.79 0 1.7.01 2.02-.59l.2-.41.13-.28a.37.37 0 0 0-.17-.5.37.37 0 0 0-.5.17l-.14.29-.19.39c-.08.16-.68.18-1.11.18h-.94c-.42 0-1.19.01-1.29-.18-1.33-2.56-2.1-4.72-2.35-8.78-.17-2.72.38-5.02 1.54-6.48l.05-.08c.29-.45.5-.9.66-1.33h3.52c.16.44.37.88.65 1.32l.07.11c.34.42.69 1.04.94 1.66.08.19.3.29.49.21.19-.08.29-.3.21-.49a7.62 7.62 0 0 0-1.03-1.82l-.05-.08a6.02 6.02 0 0 1-.57-5.23l.02-.04c.24-.49.48-1.04.58-1.75.1-.73.14-2.09-.69-3.04-.56-.65-1.35-.96-2.39-.96zm.02.75c.8 0 1.4.23 1.79.68.64.73.59 1.85.51 2.45a4.6 4.6 0 0 1-.51 1.52l-.04.09c-.07.15-.14.36-.2.62h-3.14c-.09-.38-.19-.61-.19-.61l-.03-.11a5.02 5.02 0 0 1-.51-1.52c-.08-.6-.13-1.72.51-2.45.39-.44 1-.67 1.81-.67zm-1.44 6.1h2.86c-.09.7-.1 1.56.12 2.49h-3.08c.21-.95.19-1.82.1-2.49zm10.27 5.95c-2.15 0-4.09.92-5.45 2.38a7.43 7.43 0 0 0 .1 10.23 7.4 7.4 0 0 0 5.34 2.27c2.44 0 4.6-1.18 5.96-2.99a7.39 7.39 0 0 0 1.48-4.45c0-2.05-.83-3.91-2.18-5.26a7.42 7.42 0 0 0-5.25-2.18zm0 .75a6.7 6.7 0 0 1 4.72 1.95 6.66 6.66 0 0 1 1.97 4.74c0 1.76-.68 3.37-1.8 4.56a6.66 6.66 0 0 1-4.89 2.13 6.67 6.67 0 0 1-4.91-2.15 6.68 6.68 0 0 1-.04-9.04 6.7 6.7 0 0 1 4.95-2.19zm.09 1.96a.7.7 0 0 0 0 1.38.7.7 0 0 0 0-1.38zm-3.12 1.07a.7.7 0 0 0 0 1.38.7.7 0 0 0 0-1.38zM21 23.23a.7.7 0 0 0 0 1.38.7.7 0 0 0 0-1.38z\" clip-rule=\"evenodd\"/></svg>";
},"useData":true});