var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-spot__svg--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":78}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 142 99\" class=\"m-spot__svg "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":85}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"svgClass") || (depth0 != null ? lookupProperty(depth0,"svgClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"svgClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":86},"end":{"line":1,"column":98}}}) : helper)))
    + "\"><path fill=\"#F5F7F7\" d=\"M1.6 55.1C6.6 43.5 48 21 85.2 26.8c15.3 2.4 49.4 12 54.2 24.3 11.1 29.1-24.3 45.4-49.5 47-33.4 2.3-75.9-6-81-13-4.3-6.2-12.3-18.4-7.3-30\"/><path fill=\"#FFF\" d=\"M51.8 73.5h37.1V11.7H51.8v61.8z\"/><path fill=\"#E3E6E9\" d=\"M87.9 73.5V11.9c0-.2-.2-.3-.4-.2l-2.4.8s1.2 59.8-10 61h12.8z\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M51.8 19v-7.3h17.4M51.8 24.5v-2M88.9 32.1v41.4H51.8V28.1M66.2 67.3h8.3\"/><path fill=\"#FFF\" d=\"M89.7 29.4c-2 0-4-.3-5.8-.8-.2 0-.5 0-.7.2-2.4 2-6.6 2.2-8.3 2.2h-.6c1-.9 1.8-2.7 2.4-5.4.1-.3 0-.6-.2-.8-3-2.6-4.7-6-4.7-9.4 0-7.8 8-14 17.9-14s17.8 6.2 17.8 14c0 7.7-8 14-17.8 14\"/><path fill=\"none\" stroke=\"#7C8797\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M89.7 29.4c-2 0-4-.3-5.8-.8-.2 0-.5 0-.7.2-2.4 2-6.6 2.2-8.3 2.2h-.6c1-.9 1.8-2.7 2.4-5.4.1-.3 0-.6-.2-.8-3-2.6-4.7-6-4.7-9.4 0-7.8 8-14 17.9-14s17.8 6.2 17.8 14c0 7.7-8 14-17.8 14z\"/><path fill=\"#8BC72A\" d=\"M94.8 17.8v-3.1c0-2-1-3.8-2.6-4.5 0-.8-.6-1.4-1.3-1.4s-1.3.6-1.4 1.4c-1.4.6-2.5 2.4-2.5 4.5v3c0 1-.6 1.7-1.4 1.7h10.5c-.7 0-1.3-.7-1.3-1.6z\"/><path fill=\"none\" stroke=\"#377800\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M94.4 17.8v-3.1c0-2-1.3-3.8-3-4.5a1.7 1.7 0 0 0-3.4 0 4.7 4.7 0 0 0-3.1 4.5v3c0 1-.8 1.8-1.7 1.8h13c-1 0-1.8-.8-1.8-1.7z\"/><path fill=\"none\" stroke=\"#377800\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M91.3 19.4c0 1-.7 1.7-1.6 1.7s-1.7-.7-1.7-1.7\"/><path fill=\"#619F00\" d=\"M91 11.8v6.3h2.1c0-1.9 0-3.2-.2-4.1-.1-1-.7-1.7-1.8-2.2z\"/></svg>";
},"useData":true});