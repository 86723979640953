var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 38 38\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.9 6a.38.38 0 0 0-.17.04L4.2 13.3a.38.38 0 0 0 .33.67L18.9 6.8l14.47 7.18a.37.37 0 1 0 .33-.67L19.06 6.04A.38.38 0 0 0 18.9 6zm-2.16 6.01a2.4 2.4 0 0 0-2.4 2.4v3.48h-1.05c-.05 0-.49 0-.79.39-.27.33-.33.83-.18 1.46L13 22.9v.86c0 .52.43.95.96.95h4.56v2.96H16.1c-.96 0-1.74.75-1.82 1.69a1.3 1.3 0 0 0 .32 2.55c.72 0 1.31-.59 1.31-1.31a1.3 1.3 0 0 0-.87-1.22c.06-.53.5-.96 1.06-.96h2.42v.95a1.3 1.3 0 0 0-.94 1.23 1.31 1.31 0 0 0 2.62 0c0-.59-.4-1.07-.93-1.23v-.95h2.42c.55 0 1 .43 1.05.96a1.3 1.3 0 0 0-.87 1.22 1.31 1.31 0 0 0 2.62 0c0-.6-.42-1.1-.99-1.24a1.82 1.82 0 0 0-1.8-1.69h-2.43v-2.96h4.56a.95.95 0 0 0 .95-.95v-.86l.68-3.16c.15-.63.1-1.13-.17-1.46a1.06 1.06 0 0 0-.79-.4h-1.05v-3.46a2.4 2.4 0 0 0-2.4-2.4zm0 .75h4.3c.92 0 1.66.75 1.66 1.66v6.63H15.1v-6.63c0-.91.74-1.66 1.65-1.66zm-8.72 1.11a.38.38 0 0 0-.37.38v15.29a.38.38 0 0 0 .75 0v-15.3a.38.38 0 0 0-.38-.37zm21.87 0a.38.38 0 0 0-.37.38v15.29a.38.38 0 0 0 .75 0v-15.3a.38.38 0 0 0-.38-.37zm-16.6 4.77h1.05v2.4h-.38c-.21 0-.4.08-.55.2l-.36-1.67c-.12-.52-.03-.74.03-.82.08-.1.2-.12.2-.12zm10.16 0h1.05s.12 0 .2.1c.06.09.16.3.03.84l-.35 1.66a.93.93 0 0 0-.55-.2h-.38zm-9.5 3.15h9.88c.11 0 .2.1.2.21v1.76a.2.2 0 0 1-.2.2h-9.87a.2.2 0 0 1-.2-.2V22c0-.11.09-.2.2-.2zm.65 8.25c.32 0 .56.25.56.56a.56.56 0 0 1-.56.56.56.56 0 0 1 0-1.12zm4.3 0a.56.56 0 1 1-.56.56.56.56 0 0 1 .56-.56zm4.29 0a.56.56 0 1 1-.57.56.56.56 0 0 1 .56-.56z\"/></svg>";
},"useData":true});