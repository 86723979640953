var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":107}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 17 18\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":124}}}) : helper)))
    + "\"><path fill=\"currentColor\" d=\"M4.1 16.13a.63.63 0 0 1-.35-.11.65.65 0 0 1-.22-.3.58.58 0 0 1 0-.37l.12-.37 1.46-4.55-3.54-3.05-.34-.29a.54.54 0 0 1-.18-.27.6.6 0 0 1 .57-.78h4.67L7.73 2.2l.21-.56c.04-.11.11-.2.21-.27a.6.6 0 0 1 .35-.1c.12 0 .23.03.33.09a.5.5 0 0 1 .23.28l.24.64 1.41 3.76h4.67a.6.6 0 0 1 .34.11.6.6 0 0 1 .24.64.54.54 0 0 1-.19.3l-.46.4-3.42 2.93 1.49 4.18.26.74a.6.6 0 0 1-.17.65c-.1.09-.24.13-.37.13s-.26-.03-.34-.08l-.03-.02-.51-.36-3.72-2.59-3.65 2.66-.4.29a.6.6 0 0 1-.35.11zM1.62 6.64l4.04 3.46c.09.08.13.2.09.32L4.1 15.53l4.22-3.07c.1-.08.24-.08.35 0l4.37 3.05-1.78-5.08a.3.3 0 0 1 .09-.33l4.03-3.46H10.5a.3.3 0 0 1-.28-.19L8.5 1.84l-1.72 4.6a.31.31 0 0 1-.28.2H1.62z\"/></svg>";
},"useData":true});